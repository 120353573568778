import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import '../assets/css/styles.css'
import "tippy.js/dist/tippy.css";
import 'tippy.js/animations/scale.css'
import 'tippy.js/animations/scale-subtle.css'
import 'tippy.js/animations/scale-extreme.css'
import eventBus from './services/eventBus'
import vueCookies from 'vue-cookies'



const app = createApp(App)
const pinia = createPinia();
app.use(pinia)
app.use(router)
app.use(vueCookies, { expires: '1d' })
app.use(eventBus)
app.mount('#app')