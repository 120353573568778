import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './pages/LoginPage.vue';
import IndexPage from './pages/Index.vue';
import CourseClass from './components/CourseClass.vue';
import HelloWorld from './components/HelloWorld.vue';
import pageNotFound404 from './components/404page.vue';
import CoursesPage from './components/CoursesPage.vue';
import http from './services/http';
import Cookies from 'js-cookie';
import RecoveryPage from './pages/RecoveryPage.vue';

const routes = [
  { path: '/login', component: LoginPage },
  { path: '/recovery/:token', component: RecoveryPage },
  {
    path: '/', component: IndexPage, meta: { requiresAuth: true }, children: [
      { path: '', redirect: '/learning' },
      { path: 'learning', component: CoursesPage },
      { path: 'learning/:course/lecture/:lesson', component: CourseClass },
    ]
  },
  {
    path: '/finance', component: IndexPage, meta: { requiresAuth: true }, children: [
      { path: '', redirect: '/finance/dashboard' },
      { path: 'cashflow', component: HelloWorld },
      { path: 'dashboard', component: HelloWorld },
    ]
  },
  {
    path: '/reports', component: IndexPage, children: [
      { path: '', redirect: '/reports/dashboard' },
      { path: 'dashboard', component: HelloWorld },
      { path: 'finance', component: HelloWorld },
      { path: 'sales', component: HelloWorld },
    ]
  },
  // 404 page 
  { path: '/:pathMatch(.*)*', component: pageNotFound404 }
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes: routes
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');

  if (to.path === '/login' && token) {
    try {
      const response = await http.post('/logout');
      if (response.status === 200) {
        Cookies.remove('refresh_token');
        localStorage.clear();
      }
    } catch (error) {
      console.error('Erro na requisição de logout:', error);
    }
  }

  if (to.meta.requiresAuth && !token) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
    localStorage.clear();
  } else {
    next();
  }
});

export default router;