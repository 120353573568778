<template>
    <div class="modal fade" id="courseModal" tabindex="-1" aria-labelledby="courseModalLabel" data-bs-backdrop="static"
        data-bs-keyboard="false" aria-hidden="true">
        <div class="modal-dialog px-sm-4 p-2">
            <div class="modal-content">
                <div class="modal-header p-0">
                    <div v-if="!loadingPicture" @click="updateCourseCover"
                        class="justify-content-center align-items-center hover-div">
                        <h5 class="modal-title text-white d-none">Mudar capa</h5>
                    </div>
                    <img id="course-cover"
                        :src="`${locationURL}/api/course_cover/${course && course.id ? course.id : 0}`" alt="cover"
                        class="img-fluid cover-img" />
                    <div class="close-modal-btn">
                        <button type="button" class="btn-close btn-close-white" @click="confirmAction"></button>
                    </div>
                </div>
                <div class="modal-wrapper p-3 px-4">
                    <div class="modal-body m-0 p-0 py-2 d-flex justify-content-between flex-wrap">
                        <h2 class="modal-title" id="ofModalLabel">{{ course ? 'Editar curso' : 'Adicionar curso' }}</h2>
                        <div class="form-check form-switch d-flex flex-column justify-content-end align-items-end mx-1">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Visível</label>
                            <input class="form-check-input" type="checkbox" role="switch" id="visibleCourseSwitch"
                                v-model="courseRef.visible" @change="this.edited = true">
                        </div>

                    </div>
                    <div class="modal-main-content">
                        <div class="modal-body p-0 py-2">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingInput" placeholder="Título do curso"
                                    v-model="courseRef.name"
                                    @input="($event) => { this.edited = true; removeInvalid($event) }"
                                    :class="{ 'is-invalid': invalidInputs.includes('floatingInput') }">
                                <label for="floatingInput">Título</label>
                                <div class="invalid-feedback ms-2">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <textarea class="form-control mb-3" placeholder="Leave a comment here"
                                    id="floatingTextarea2" style="height: 100px"
                                    @input="($event) => { this.edited = true; removeInvalid($event) }"
                                    :class="{ 'is-invalid': invalidInputs.includes('floatingTextarea2') }"
                                    v-model="courseRef.description"></textarea>
                                <label for="floatingTextarea2">Descrição</label>
                                <div class="invalid-feedback ms-2">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div class="form-floating mb-3">

                                <select class="form-select" id="floatingSelectcoordinator"
                                    aria-label="Floating label select example"
                                    :class="{ 'is-invalid': invalidInputs.includes('floatingSelectcoordinator') }"
                                    @change="($event) => { this.edited = true; removeInvalid($event) }">
                                    <option selected value="0">Selecione um coordenador</option>
                                    <option v-for="instructor in instructorsList" :key="instructor.id"
                                        :value="instructor.id" :selected="instructor.id == courseRef.coordinator">{{
                        instructor.name }} {{ instructor.surname }}</option>
                                </select>
                                <label for="floatingSelectcoordinator">Coordenação do curso</label>
                                <div class="invalid-feedback ms-2">
                                    Campo obrigatório
                                </div>
                            </div>

                            <div v-for="index in instructorIndex" :key="index">
                                <div :id="index + '-instructor-div'" class="hstack gap-1 instructor-line">
                                    <div class="form-floating mb-3 w-100">
                                        <select class="form-select form-select-instructors"
                                            :id="'floatingSelectInstructor' + index"
                                            aria-label="Floating label select example" @change="this.edited = true">

                                            <option selected value="0">Selecione um instrutor</option>
                                            <option v-for="instructor in instructorsList" :key="instructor.id"
                                                :value="instructor.id"
                                                :selected="selectedInstructors && selectedInstructors.length >= index && instructor.id == selectedInstructors[index - 1]['instructor']">
                                                {{ instructor.name }} {{ instructor.surname }}
                                            </option>

                                        </select>
                                        <label :for="'floatingSelectInstructor' + index">Instrutor</label>
                                    </div>
                                    <div v-if="index !== 1">
                                        <button type="button" class="btn btn-outline-danger mb-3 delete-instructor"
                                            @click="removeExtraInstructor">
                                            <i class="bi bi-x fs-3"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-row gap-2">
                                <button type="button" class="btn btn-outline-info mb-3 w-100"
                                    @click="openInstructorModal"><i class="bi bi-plus-lg me-2"></i>Criar
                                    novo Instrutor</button>
                                <button type="button" class="btn btn-outline-primary mb-3 w-100"
                                    @click="addExtraInstructor"><i class="bi bi-plus-lg me-2"></i>Adicionar
                                    instrutor</button>
                            </div>
                            <h5 class="text-secondary my-3">Conteúdo do curso</h5>

                            <div class="accordion accordion-flush mb-3" id="accordionFlushExample">
                                <div v-for="module in modules.filter((item) => item.visible === true)"
                                    :key="module.order" class="accordion-item" data-editing="false">
                                    <h2 class="accordion-header" :id="'flush-headingOne' + module.order">
                                        <button class="accordion-module-button accordion-button collapsed" type="button"
                                            :id="'flush-button' + module.order"
                                            @click="handleCollapseAccordion('flush-button' + module.order, 'flush-collapseOne' + module.order)"
                                            :disabled="module.order === editingModule">

                                            <input v-if="module.order === editingModule" type="text"
                                                class="form-control-plaintext w-100 p-0 m-0 me-3 w-100"
                                                :value="module.name" :id="'mondule-name-ipt' + module.order"
                                                @keydown.enter="updateModuleName(module.order, $event.target.value)"
                                                @keydown.esc.prevent.stop="editingModule = null"
                                                @blur="updateModuleName(module.order, $event.target.value)"
                                                placeholder="Nome do módulo"
                                                @change="updateModuleName(module.order, $event.target.value)">

                                            <p v-else class="w-100 p-0 m-0 me-3">{{ module.order }}. {{ module.name }}
                                            </p>

                                            <i v-if="module.order !== editingModule && (module.lessons.length === 0 || module.lessons.filter((lesson) => lesson.visible).length === 0)"
                                                class="bi bi-trash mx-2" @click.prevent.stop="deleteModule(module)"></i>
                                            <i v-if="module.order !== editingModule" class="bi bi-pen mx-2"
                                                @click.prevent.stop="editModule(module.order)"></i>
                                            <i class="bi bi-chevron-down"></i>
                                        </button>
                                    </h2>

                                    <div :id="'flush-collapseOne' + module.order"
                                        class="accordion-module-collapse accordion-collapse collapse">
                                        <div class="accordion-body px-2 pt-1">
                                            <div v-for="lesson in module.lessons.filter((item) => item.visible === true)"
                                                :key="lesson.order">
                                                <div class="lesson-card w-100 p-2"
                                                    @click="openLessonModal(module, lesson)">
                                                    <div>{{ lesson.order }}. {{ lesson.name }}</div>
                                                </div>
                                            </div>

                                            <button type="button" class="btn btn-link mb-3 w-100"
                                                @click="openLessonModal(module)"><i class="bi bi-plus-lg me-2"></i>Criar
                                                nova aula</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <form v-if="addingModule" @submit.prevent="newModule">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" :class="validModuleName ? '' : 'is-invalid'"
                                        id="floatingInputModuleName" placeholder="Nome do módulo"
                                        @input="validModuleName = true">
                                    <label for="floatingInputModuleName">Nome do módulo</label>
                                    <div id="validationModuleNameFeedback" class="invalid-feedback ms-2"
                                        :class="validModuleName ? 'd-none' : ''">
                                        Campo obrigatório
                                    </div>
                                </div>

                                <div class="d-flex flex-row gap-2">
                                    <button type="button" class="btn btn-outline-secondary mb-3 w-100"
                                        @click="addModule"><i class="bi bi-x-lg me-2"></i>Cancelar</button>
                                    <button type="button" class="btn btn-outline-primary mb-3 w-100"
                                        @click="newModule"><i class="bi bi-plus-lg me-2"></i>Adicionar</button>
                                </div>

                            </form>
                            <div v-else>
                                <button type="button" class="btn btn-outline-primary w-100 mb-3" @click="addModule"><i
                                        class="bi bi-plus-lg me-2"></i>Adicionar
                                    módulo</button>
                            </div>
                            <h5 class="text-secondary my-3">Restrições de acesso</h5>
                            <div v-if="selectedRestrictions && selectedRestrictions.length > 0">
                                <div class="d-flex flex-wrap gap-2 mb-4">
                                    <div v-for="restriction in selectedRestrictions" :key="restriction.id">
                                        <span class="badge rounded-pill text-bg-info">{{ restriction.name }}
                                            <i class="bi bi-x-lg ms-2" style="cursor: pointer;"
                                                @click="selectedRestrictions = selectedRestrictions.filter(r => r.id !== restriction.id)"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-outline-primary w-100 mb-3"
                                @click="openAccessRestrictionModal"><i class="bi bi-plus-lg me-2"></i>Adicionar
                                regra de acesso</button>
                        </div>
                    </div>
                    <div class="modal-footer p-0">
                        <button type="button" class="btn btn-outline-secondary" @click="confirmAction">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click="saveDraftConfirm">Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <lessonModal @playSound="this.$emit('playSound', $event)" @sendLesson="appendLesson"
        @deleteLesson="deleteLesson($event)" :lessonData="currentLessonData"></lessonModal>
    <instructorModal @playSound="this.$emit('playSound', $event)" @updateInstructors="getInstructors"></instructorModal>
    <accessRestrictionModal :course="course" :selectedPrivileges="selectedRestrictions"
        @updatePrivileges="updatePrivileges"></accessRestrictionModal>

    <confirmActionModal @confirmAction="closeModal" @cancelAction="this.$emit('toggleCourseModal')" :danger="true"
        :title="'Atenção!'"
        :content="'Têm certeza que de deseja sair sem salvar? Todas as eventuais alterações serão perdidas.'"
        :id="'confirmUnsaved'"></confirmActionModal>

    <confirmActionModal @confirmAction="sendCourse" @cancelAction="this.$emit('toggleCourseModal')" :title="'Atenção!'"
        :content="'<p>Caso você salve o curso como está, ele será classificado como <b>rascunho</b>, e não poderá ser acessado por usuários comuns. Para não ser classificado dessa forma, o curso necessáriamente precisa conter:<br><br>1. Pelo menos um instrutor<br>2. Pelo menos um módulo<br>3. Pelo menos uma aula <br>4. Incluir uma capa para o curso<br><br> Deseja prosseguir e salvar o curso como rascunho?</p>'"
        :id="'confirmDraft'"></confirmActionModal>

</template>

<script>
import {
    ref,
    onMounted
} from 'vue';
import http from '@/services/http';
import lessonModal from './LessonModal.vue';
import instructorModal from './InstructorModal.vue';
import accessRestrictionModal from './AccessRestrictionModal.vue';
import confirmActionModal from './ConfirmActionModal.vue';

import {
    Modal
} from 'bootstrap';

export default {
    name: 'courseModal',
    props: ['course'],
    emits: ['playSound', 'toggleCourseModal', 'notifyError', 'sendLesson', 'sendCourse', 'updateInstructors'],
    components: {
        lessonModal,
        instructorModal,
        accessRestrictionModal,
        confirmActionModal
    },
    setup(props, ctx) {
        const courseRef = ref({});
        const loadingPicture = ref(false);
        let formData = new FormData();
        const instructorsList = ref([]);
        const instructorIndex = props.course ? ref(props.course.instructors.length) : ref(1);
        const addingModule = ref(false);
        const modules = ref([]);
        const validModuleName = ref(true);
        const editingModule = ref(null);
        let instructorModalElement;
        let lessonModalElement;
        let accessRestrictionModalElement;
        const currentModule = ref(null);
        const selectedRestrictions = ref([]);
        const selectedInstructors = ref(null);
        let courseCoverImg = ref(null);
        const locationURL = ref('');
        const courseModal = ref(null);
        const edited = ref(false);
        const lessonModel = {
            id: null,
            visible: true,
            name: '',
            url: '',
            resources: []
        };
        const currentLessonData = ref(lessonModel);
        courseRef.value.visible = true;
        const invalidInputs = ref([]);


        const updateCourseCover = async () => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/jpg, image/jpeg, image/png';
            fileInput.onchange = async function (e) {
                const file = e.target.files[0];

                if (file) {
                    edited.value = true;
                    loadingPicture.value = true;
                    courseCoverImg.value.src = URL.createObjectURL(file);
                    loadingPicture.value = false;
                    formData.append('course_cover', file);
                }
            };
            fileInput.click();
        };

        const getAllSelectedInstructors = () => {
            const allSelects = document.querySelectorAll('.form-select-instructors');
            let allSelectedInstructors = [];
            allSelects.forEach((select) => {
                allSelectedInstructors.push(select.value);
            });

            allSelectedInstructors = allSelectedInstructors.filter((item) => item !== '0');
            allSelectedInstructors = [...new Set(allSelectedInstructors)];

            return allSelectedInstructors;
        }

        const sendCourse = () => {

            const visibilitySwitch = document.getElementById('visibleCourseSwitch');

            const allSelectedInstructors = getAllSelectedInstructors();
            console.log(allSelectedInstructors);

            courseRef.value.instructors = selectedInstructors.value;
            courseRef.value.modules = modules.value;
            courseRef.value.restrictions = selectedRestrictions.value;
            courseRef.value.coordinator = document.getElementById('floatingSelectcoordinator').value;
            courseRef.value.description = document.getElementById('floatingTextarea2').value.replace(/\n/g, '<br>');
            courseRef.value.instructors = allSelectedInstructors;
            courseRef.value.visible = visibilitySwitch.checked;

            console.log(courseRef.value);

            formData.set('data', JSON.stringify(courseRef.value));

            ctx.emit('sendCourse', formData);
        }

        const getInstructors = async () => {
            try {
                const response = await http.get('/instructors/*');
                if (response.status === 200) {
                    instructorsList.value = response.data;
                }
            } catch (error) {
                console.log(error);
            }
        }

        const openInstructorModal = () => {
            instructorModalElement = new Modal(document.getElementById('instructorModal'));
            ctx.emit('toggleCourseModal');
            instructorModalElement.show();
        }

        const confirmAction = () => {
            if (edited.value === true) {
                const confirmUnsaved = new Modal(document.getElementById('confirmUnsaved'));
                ctx.emit('toggleCourseModal');
                ctx.emit('playSound', 'somethingWeird')
                confirmUnsaved.show();
            } else {
                closeModal();
            }
        }

        const validateModal = () => {
            const courseName = document.getElementById('floatingInput');
            const courseDescription = document.getElementById('floatingTextarea2');
            const courseCoordinator = document.getElementById('floatingSelectcoordinator');
            let invalid = false;

            if (courseName.value.trim() === '') {
                invalidInputs.value.push('floatingInput');
                invalid = true;
            }

            if (courseDescription.value.trim() === '') {
                invalidInputs.value.push('floatingTextarea2');
                invalid = true;
            }

            if (courseCoordinator.value === '0') {
                invalidInputs.value.push('floatingSelectcoordinator');
                invalid = true;
            }

            if (invalid) {
                ctx.emit('playSound', 'mistake');
                return false;
            }
            return true;
        }

        const saveDraftConfirm = () => {
            const modulesCount = modules.value.filter((module) => module.visible).length;
            let lessonsCount = 0;

            for (let i = 0; i < modulesCount; i++) {
                lessonsCount += modules.value[i].lessons.filter((lesson) => lesson.visible).length;
            }

            const allSelectedInstructors = getAllSelectedInstructors();
            const courseCoverObj = document.getElementById('course-cover');
            const courseCover = courseCoverImg.value === null || courseCoverObj.src === `${locationURL.value}/api/course_cover/0` ? false : true;

            console.log(`modulesCount: ${modulesCount}, lessonsCount: ${lessonsCount}, allSelectedInstructors: ${allSelectedInstructors.length}, courseCover: ${courseCover}`);

            if (validateModal()) {
                if (allSelectedInstructors.length === 0 || courseCover === false || lessonsCount === 0 || modulesCount === 0) {
                    const confirmUnsaved = new Modal(document.getElementById('confirmDraft'));
                    ctx.emit('toggleCourseModal');
                    ctx.emit('playSound', 'somethingWeird')
                    confirmUnsaved.show();

                } else {
                    sendCourse();
                }
            }

        }

        const updateAllLessonsOrder = () => {
            let initialOrder = 1;
            modules.value.forEach((module) => {
                module.lessons.forEach((lesson) => {
                    if (lesson.visible === true) {
                        lesson.order = initialOrder++;
                    } else {
                        lesson.order = 0;
                    }
                })
            })
        }

        const openLessonModal = (module, lesson = null) => {
            const emptyLessonModel = {
                id: null,
                visible: true,
                name: '',
                url: '',
                resources: []
            };

            currentModule.value = module;
            currentLessonData.value = lesson || emptyLessonModel;

            lessonModalElement = new Modal(document.getElementById('lessonModal'));
            ctx.emit('toggleCourseModal');
            lessonModalElement.show();
        }

        const openAccessRestrictionModal = () => {
            accessRestrictionModalElement = new Modal(document.getElementById('accessRestrictionModal'));

            ctx.emit('toggleCourseModal');
            accessRestrictionModalElement.show();
        }

        const updatePrivileges = (privileges) => {
            edited.value = true;

            selectedRestrictions.value = [];
            selectedRestrictions.value = privileges;
            accessRestrictionModalElement.hide();
        }

        const appendLesson = (lesson) => {
            edited.value = true;

            // append files in FormData object
            for (let i = 0; i < lesson.resources.length; i++) {
                if (lesson.resources[i].type === 'file' && 'src' in lesson.resources[i]) {
                    formData.append(`${lesson.resources[i].timestamp}_${lesson.resources[i].name}`, lesson.resources[i].src);
                    lesson.resources[i].src = null;
                }
            }

            const lessonObj = {
                id: lesson.id,
                name: lesson.name,
                visible: true,
                new: true,
                url: lesson.url,
                duration: lesson.duration,
                order: currentModule.value.lessons.filter(l => l.visible).length + 1,
                resources: lesson.resources
            };

            if (lesson['id']) {

                let currentLesson = currentModule.value.lessons.find(l => l.id === lesson.id);

                if (currentLesson) {
                    Object.assign(currentLesson, lesson); // Atualiza as propriedades do currentLesson com as do lesson
                }

            } else if (lesson['order']) {

                let currentLesson = currentModule.value.lessons.find(l => l.order === lesson.order);

                if (currentLesson) {
                    Object.assign(currentLesson, lessonObj);  // Atualiza as propriedades do currentLesson com as do lesson 

                }
            } else {
                lessonObj['order'] = currentModule.value.lessons.length + 1;
                currentModule.value.lessons.push(lessonObj);
            }

            // update order
            updateAllLessonsOrder();
        }

        const removeLesson = (lesson) => {
            edited.value = true;

            if (lesson.id) {
                const currentLesson = currentModule.value.lessons.find(l => l.id === lesson.id);
                currentLesson.visible = false;

            } else {
                currentModule.value.lessons = currentModule.value.lessons.filter((item) => item.order !== lesson.order);
            }

            // update order
            updateAllLessonsOrder();
        }

        const closeModal = () => {
            const modal = Modal.getInstance(document.getElementById('courseModal'));

            courseRef.value = {};
            modules.value = [];
            invalidInputs.value = [];
            selectedRestrictions.value = [];
            editingModule.value = null;
            addingModule.value = false;
            validModuleName.value = true;
            instructorIndex.value = 1;
            courseRef.value.visible = true;
            selectedInstructors.value = null;
            formData = new FormData();
            edited.value = false;

            document.getElementById("floatingSelectcoordinator").value = 0;
            document.querySelector(".form-select-instructors").value = 0;

            if (courseCoverImg.value) {
                courseCoverImg.value.src = `${locationURL.value}/api/course_cover/0`;
            }

            modal.hide();
        }

        onMounted(() => {
            locationURL.value = window.location.origin;
            courseCoverImg.value = document.getElementById("course-cover");
            getInstructors();

            const accessRestrictionModal = document.getElementById('accessRestrictionModal');
            const instructorModal = document.getElementById('instructorModal');
            const lessonModal = document.getElementById('lessonModal');
            courseModal.value = document.getElementById('courseModal');


            accessRestrictionModal.addEventListener('hidden.bs.modal', () => {
                ctx.emit('toggleCourseModal');
            })

            instructorModal.addEventListener('hidden.bs.modal', () => {
                ctx.emit('toggleCourseModal');
            })

            lessonModal.addEventListener('hidden.bs.modal', () => {
                ctx.emit('toggleCourseModal');
            })

        })

        return {
            updateCourseCover,
            instructorsList,
            loadingPicture,
            instructorIndex,
            addingModule,
            modules,
            validModuleName,
            openInstructorModal,
            openLessonModal,
            openAccessRestrictionModal,
            getInstructors,
            editingModule,
            appendLesson,
            removeLesson,
            selectedRestrictions,
            updatePrivileges,
            courseRef,
            selectedInstructors,
            courseCoverImg,
            locationURL,
            sendCourse,
            currentLessonData,
            currentModule,
            updateAllLessonsOrder,
            confirmAction,
            closeModal,
            edited,
            saveDraftConfirm,
            invalidInputs
        }
    },

    watch: {
        course: {
            handler(newValue) {
                if (newValue) {
                    this.courseRef = newValue
                    this.courseRef.description = newValue.description.replace(/<br\s*\/?>/gi, '\n')
                    this.selectedRestrictions = newValue.restrictions
                    this.instructorIndex = newValue.instructors.length === 0 ? 1 : newValue.instructors.length
                    this.selectedInstructors = newValue.instructors
                    this.modules = newValue.modules
                    this.courseCoverImg.src = `${this.locationURL}/api/course_cover/${newValue.id}`;

                } else {
                    this.courseRef = {}
                    this.courseRef.description = ''
                    this.selectedRestrictions = []
                    this.selectedInstructors = []
                    this.instructorIndex = 1
                    this.modules = []
                    this.courseRef.visible = true
                    this.courseCoverImg.src = `${this.locationURL}/api/course_cover/0`;
                }
                this.edited = false
            }
        }
    },

    methods: {

        removeInvalid(event) {
            const targetId = event.target.id;
            if (this.invalidInputs.includes(targetId)) {
                this.invalidInputs.splice(this.invalidInputs.indexOf(targetId), 1);
            }
        },

        handleCollapseAccordion(origin, target) {
            const allAccordionButtons = document.querySelectorAll('.accordion-button');
            const allAccordionItems = document.querySelectorAll('.accordion-collapse');

            allAccordionItems.forEach((item) => {
                if (item.id !== target) {
                    item.classList.add('collapse');
                }
            });

            allAccordionButtons.forEach((button) => {
                if (button.id !== origin) {
                    button.classList.add('collapsed');
                }
            });

            const currentButton = document.getElementById(origin);
            const currentItem = document.getElementById(target);

            currentButton.classList.toggle('collapsed');
            currentItem.classList.toggle('collapse');
        },

        addExtraInstructor() {
            this.edited = true
            this.instructorIndex++;
        },

        removeExtraInstructor(event) {
            this.edited = true

            const button = event.target;
            const element = button.closest('.instructor-line');
            element.remove();
        },

        printSelectedInstructors() {
            const allSelects = document.querySelectorAll('.form-select-instructors');
            let allSelectedInstructors = [];

            allSelects.forEach((select) => {
                allSelectedInstructors.push(select.value);
            });

            // filter '0' and repeat values
            allSelectedInstructors = allSelectedInstructors.filter((item) => item !== '0');
            allSelectedInstructors = [...new Set(allSelectedInstructors)];

            console.log(allSelectedInstructors);
        },

        isCurrentInstructor(id, index) {
            if (id !== undefined && index !== undefined && this.selectedInstructors) {
                console.log(`id: ${id}, index: ${index}`)
                console.log(this.selectedInstructors[index - 1])

                if (this.selectedInstructors && this.selectedInstructors[index - 1] && id === this.selectedInstructors[index - 1]['id']) {
                    return true
                }
            }

        },

        addModule() {
            this.addingModule = !this.addingModule;

            if (this.addingModule === true) {
                this.$nextTick(() => {
                    document.getElementById('floatingInputModuleName').focus();
                });

            }
            this.validModuleName = true;
        },

        newModule() {
            this.edited = true

            const moduleInput = document.getElementById('floatingInputModuleName');

            if (moduleInput.value.trim() !== '') {
                const order = this.modules.filter(module => module.visible).length + 1;
                this.modules.push({
                    name: moduleInput.value,
                    order: order,
                    visible: true,
                    lessons: []
                });
                moduleInput.value = '';
                this.addingModule = false;

                this.$nextTick(() => {
                    this.handleCollapseAccordion('flush-button' + order, 'flush-collapseOne' + order)
                })

            } else {
                moduleInput.focus();
                this.validModuleName = false;
                this.$emit('playSound', 'mistake');
            }

        },

        updateModuleName(index, value) {
            this.edited = true

            if (value.trim() === '') {
                this.editingModule = null;
                return;
            }

            this.modules[index - 1].name = value;
            this.editingModule = null;
        },

        editModule(module) {
            this.editingModule = module;

            this.$nextTick(() => {
                const input = document.getElementById(`mondule-name-ipt${module}`);
                input.focus();
            })

        },

        deleteModule(module) {
            this.edited = true

            if (module.id) {
                module.visible = false;
            } else {
                this.modules = this.modules.filter((item) => item.order !== module.order);
            }

            // update order
            this.modules.filter((item) => item.visible).forEach((item, index) => {
                item.order = index + 1;
            })
        },

        deleteLesson(lesson) {
            this.edited = true

            const moduleOrder = this.currentModule.order;

            if (lesson.id) {
                const currentLesson = this.modules.filter(module => module.visible)[moduleOrder - 1].lessons.find(l => l.id === lesson.id);
                currentLesson.visible = false;
            } else {
                this.modules[moduleOrder - 1].lessons = this.modules[moduleOrder - 1].lessons.filter((item) => item.order !== lesson.order);
            }

            // update order with function "updateAllLessonsOrder" seted in setup
            this.updateAllLessonsOrder();

        }

    }
}
</script>

<style scoped>
input {

    -webkit-autofill,
    -webkit-autofill:hover,
    -webkit-autofill:focus,
    -webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 1000px white inset;
    }
}

input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
}

.form-floating> :disabled~label::after,
.form-floating>.form-control:disabled~label::after {
    background-color: transparent !important;
}

.modal-dialog {
    max-width: 550px;
    width: auto;
}

.modal-header {
    position: relative;
}

.hover-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    cursor: pointer;
    border-radius: 15px 15px 0 0;
}

.hover-div:hover {
    opacity: 1;
}

.hover-div:hover h5 {
    display: block !important;
}

.close-modal-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    border-radius: 50%;
    background-color: rgba(var(--bs-secondary-rgb), 0.2);
}

.close-modal-btn button {
    margin: 3px;
}

.close-modal-btn:hover {
    background-color: rgba(var(--bs-secondary-rgb), 0.6);
}

.cover-img {
    border-radius: 15px 15px 0 0;
    aspect-ratio: 16 / 5;
    min-width: 100%;
    object-fit: cover;
    object-position: center;
}

.form-check-input {
    width: 3em !important;
    height: 1.3rem !important;
    margin-top: 0.5rem;
}

/* .modal-main-content {
  max-height: calc(100vh - 410px);
  overflow-y: auto;
} */

.delete-instructor {
    border-radius: 100%;
    width: 28px;
    height: 28px;
    padding: 0px;
}

.accordion-button::after {
    display: none;
}

.accordion-item .accordion-header .bi {
    font-size: larger;
}

.accordion-item .accordion-header .bi-pen:hover {
    color: var(--bs-primary);
}

.accordion-item .accordion-header .bi-trash:hover {
    color: var(--bs-danger);
}

.accordion-item .accordion-header .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
    font-size: 1rem;
}

.accordion-button:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
}

.accordion-collapse {
    grid-template-rows: 0fr;
    transition: grid-template-rows 350ms;
}

.accordion-collapse:not(.collapse) {
    grid-template-rows: 1fr;
}

.accordion-collapse:not(.collapse)>div {
    padding-bottom: 7px;
}

.accordion-collapse>div {
    overflow: hidden;
}

.lesson-card:hover {
    background-color: var(--bs-light);
    cursor: pointer;
}
</style>
