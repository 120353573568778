<template>
    <div class="col main-div">
        <div class="pt-4 px-5 shadow-lg form-div">
            <div class="brand-logo d-flex align-items-center justify-content-center logo-img mb-4 mt-3">
                <div class="d-flex align-items-center no-wrap">
                    <img src="../../assets/images/logos/kahun_logo_light.png" alt="petcon" class="img-fluid"
                        style="height: 60px; width: auto;" />
                </div>
            </div>
            <div v-if="singUp">
                <div v-if="loading">
                    <div class="spinner-border text-primary mb-2" role="status"></div>
                    <h5>Por favor, aguarde...</h5>
                    <p>Estamos validando as informações</p>
                </div>
                <div v-else>
                    <div v-if="failOnLoad">
                        <div class="alert alert-danger text-start fs-3 mb-3" role="alert">
                            <p id="alert-text" v-html="alertText"></p>
                        </div>
                    </div>
                    <div v-else>
                        <h2 class="fw-bold mb-3">Bem vindo!</h2>
                        <p class="mb-4">Você foi convidado à ingressar no sistema da Kahun. Para concluir o seu
                            cadastro, preencha os dados abaixo.</p>
                        <form class="pass-div mt-2" id="pass-div-singup" @submit.prevent="singUp">
                            <input type="email" class="form-control mb-3" placeholder="E-mail" aria-label="Email"
                                aria-describedby="basic-addon1" id="email-ipt-singup" disabled v-model="inviteEmail">

                            <div class="mb-3">
                                <input type="text" class="form-control" placeholder="Username" aria-label="Username"
                                    aria-describedby="basic-addon2" id="user-ipt-singup" @input="usernameError = null"
                                    :class="{ 'is-invalid': usernameError !== null }">
                                <div class="invalid-feedback mx-2">
                                    {{ usernameError }}
                                </div>
                            </div>

                            <div class="mb-3">
                                <input type="text" class="form-control" placeholder="Nome" aria-label="Nome"
                                    aria-describedby="basic-addon3" id="name-ipt-singup" @input="removeInvalid($event)"
                                    :class="{ 'is-invalid': invalidInputs.includes('name-ipt-singup') }">
                                <div class="invalid-feedback mx-2">
                                    Esse campo é obrigatório.
                                </div>
                            </div>

                            <div class="mb-3">
                                <input type="text" class="form-control" placeholder="Sobrenome" aria-label="Sobrenome"
                                    aria-describedby="basic-addon4" id="surname-ipt-singup"
                                    @input="removeInvalid($event)"
                                    :class="{ 'is-invalid': invalidInputs.includes('surname-ipt-singup') }">
                                <div class="invalid-feedback mx-2">
                                    Esse campo é obrigatório.
                                </div>
                            </div>

                            <div class="mb-3">
                                <input type="password" class="form-control" placeholder="Senha" aria-label="Password"
                                    aria-describedby="basic-addon2" id="pass-ipt-singup"
                                    @input="($event) => { removeInvalid($event); isEqualPasswords(); removeInvalid({ target: { id: 'pass-ipt2-singup' } }) }"
                                    :class="{ 'is-invalid': invalidInputs.includes('pass-ipt-singup') }">
                            </div>

                            <div class="mb-3">
                                <input type="password" class="form-control" placeholder="Confirmar senha"
                                    aria-label="Password" aria-describedby="basic-addon3" id="pass-ipt2-singup"
                                    @input="($event) => { isEqualPasswords(); removeInvalid($event); removeInvalid({ target: { id: 'pass-ipt-singup' } }) }"
                                    :class="{ 'is-invalid': invalidInputs.includes('pass-ipt2-singup') }">
                            </div>

                            <div>
                                <div class="alert alert-info text-start fs-2 mb-3" role="alert">
                                    <p v-html="message"></p>
                                </div>
                                <button class="btn btn-primary btn-lg" @click.prevent="singUpUser(true)">Concluir
                                    cadastro</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div v-else>
                <form v-if="!recoveryMode" class="pass-div mt-2" id="pass-div" @submit.prevent="login">
                    <input type="text" class="form-control mb-3" placeholder="Username" aria-label="Username"
                        aria-describedby="basic-addon1" id="user-ipt">
                    <input type="password" class="form-control mb-4" placeholder="Senha" aria-label="Password"
                        aria-describedby="basic-addon2" id="pass-ipt">
                    <div>
                        <button class="btn btn-primary btn-lg" @click.prevent="login">Login</button>
                        <button type="button" class="btn btn-link m-0" @click="recoveryMode = true">Esqueci minha
                            senha</button>
                    </div>
                </form>
                <div v-else>
                    <form class="recovery-div mt-2" id="pass-div" @submit.prevent.stop>
                        <div v-if="sentRecovery" class="alert alert-success" role="alert">
                            Email enviado com sucesso
                        </div>
                        <p class="text-start"> Insira o email ou nome de usuário associado a sua conta para
                            redefinir
                            sua senha. <strong>Caso
                                ela exista</strong>, você receberá um email com instruções para recuperá-la.</p>
                        <input type="email" class="form-control mb-3" placeholder="Email ou nome de usuário"
                            aria-label="Email" aria-describedby="basic-addon3" id="recovery-email-ipt"
                            :disabled="sending">
                        <div>

                            <button class="btn btn-primary btn-lg" @click.prevent.stop="recovery" :disabled="sending">
                                <span v-if="sending" class="spinner-border spinner-border-sm me-2 text-light"
                                    role="status" aria-hidden="true"></span>
                                {{ sending ? 'Enviando...' : 'Recuperar senha' }}</button>
                            <button type="button" class="btn btn-link m-0" @click="recoveryMode = false">←
                                Voltar</button>
                        </div>
                    </form>

                </div>
            </div>

            <img src="../../assets/images/igzan_login.png" alt="logo" class="mb-4 mt-3 logo-igzan">
        </div>
    </div>
</template>

<script>
import auth from "@/services/auth";
import { onMounted } from "vue";
import sha256 from "crypto-js/sha256";
import router from "@/router";
import { inject } from 'vue'
import { ref } from 'vue'
import http from "@/services/http"
import { playSound } from "@/services/playSound";


export default {
    name: "LoginForm",
    emits: ['notifyError', 'notifySuccess'],
    setup(props, ctx) {
        const $cookies = inject('$cookies');
        const recoveryMode = ref(false);
        const sentRecovery = ref(false);
        const sending = ref(false);
        const singUp = ref(false);
        const loading = ref(false)
        const failOnLoad = ref(false)
        const inviteEmail = ref(null);
        const usernameError = ref(null);
        const invalidInputs = ref([]);
        const alertText = ref('');
        const message = ref(`
        <p>Sua senha deve conter ao menos:</p>
        <ul>
            <li>- 8 caracteres <span class="text-danger">✗</span></li> 
            <li>- 1 maiúscula <span class="text-danger">✗</span></li></li>
            <li>- 1 minúscula <span class="text-danger">✗</span></li></li>
            <li>- 1 número <span class="text-danger">✗</span></li></li>
            <li>- 1 caractere especial <span class="text-danger">✗</span></li></li>
            <li id="equal-passwords-singup">- As senhas devem ser iguais <span class="text-danger">✗</span></li></li>
        </ul>
        `);

        const recovery = async () => {
            sentRecovery.value = false;
            const email = document.getElementById("recovery-email-ipt").value;

            if (email.trim() === "") {
                ctx.emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Informações incompletas!</h5><p class='text-white mb-0'>Por favor, preencha o campo de email!</p>", id: 2 })

            } else {

                try {
                    sending.value = true;
                    const response = await auth.post("/recovery", { email: email });

                    if (response.status === 200) {
                        sentRecovery.value = true;

                    } else {
                        ctx.emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Erro na solicitação!</h5><p class='text-white mb-0'>Por favor, tente novamente!</p>", id: 2 })
                    }

                } catch (error) {
                    ctx.emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Erro na solicitação!</h5><p class='text-white mb-0'>Por favor, tente novamente!</p>", id: 2 })
                }

                sending.value = false;
            }

        }

        const login = async () => {
            const user = document.getElementById("user-ipt").value;
            const pass = document.getElementById("pass-ipt").value;
            const hashedPass = sha256(pass).toString();

            const data = {
                username: user,
                password: hashedPass
            };

            if (user === "" || pass === "") {
                ctx.emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Informações incompletas!</h5><p class='text-white mb-0'>Por favor, preencha todos os campos para logar!</p>", id: 2 })
                playSound('mistake');

            } else {

                try {
                    const response = await auth.post("/login", data);

                    const responseData = response.data;

                    const profile_picture = responseData.profile_picture;

                    if (profile_picture) {
                        localStorage.setItem("profile_picture", profile_picture);
                    } else {
                        localStorage.removeItem("profile_picture");
                    }

                    localStorage.setItem("username", user);
                    localStorage.setItem("token", responseData.tokens.access_token);
                    localStorage.setItem("user_roles", JSON.stringify(responseData.user_roles));
                    localStorage.setItem("play_intro", true);
                    localStorage.setItem("theme", responseData.default_theme)
                    localStorage.setItem("systemSounds", responseData.allow_system_sounds);
                    localStorage.setItem("multi_access", responseData.multi_access);

                    if (responseData.first_access) {
                        localStorage.setItem("first_access", true);
                    }

                    if (responseData.admin) {
                        localStorage.setItem("user_admin", responseData.admin);
                    }
                    $cookies.set("refresh_token", responseData.tokens.refresh_token, '30d', '/', false, 'Lax');

                    const urlParams = new URLSearchParams(window.location.search);
                    const redirectParam = urlParams.get('redirect');

                    if (redirectParam) {
                        router.push({ path: redirectParam });
                    } else {
                        router.push({ path: "/" });
                    }


                } catch (error) {
                    if (error.response.status === 400) {
                        ctx.emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Credenciais inválidas!</h5><p class='text-white mb-0'>Revise as informações e tente novamente</p>", id: 1 })
                        playSound('mistake');

                    } else if (error.response.status === 500) {
                        ctx.emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Erro ao conectar!</h5><p class='text-white mb-0'>Servidor indisponível no momento</p><p class='text-white mb-0'>Err.:" + error.response.status + "</p>", id: 1 })
                        playSound('mistake');

                    } else {
                        console.log(error);
                    }
                }
            }
        }


        onMounted(() => {
            const usernameInput = document.getElementById("user-ipt");

            if (usernameInput) {
                usernameInput.focus();
            }
        });

        return {
            login,
            recoveryMode,
            sentRecovery,
            recovery,
            sending,
            singUp,
            loading,
            message,
            failOnLoad,
            inviteEmail,
            usernameError,
            invalidInputs,
            alertText
        };
    },
    watch: {
        recoveryMode: function (newVal) {
            if (newVal === true) {

                this.$nextTick(() => {
                    const emailInput = document.getElementById("recovery-email-ipt");
                    emailInput.focus();
                })

            } else {

                this.$nextTick(() => {
                    // remove "recover" query param from url
                    const urlParams = new URLSearchParams(window.location.search);

                    if (urlParams.has('recover')) {
                        urlParams.delete('recover');
                        window.history.replaceState({}, '', `${window.location.pathname}?${urlParams.toString()}`);
                    }

                    const usernameInput = document.getElementById("user-ipt");
                    usernameInput.focus();
                })
            }
        }
    },
    mounted() {
        if (this.$route.query.recover === "true") {
            this.recoveryMode = true
        }

        const validateToken = async (token) => {
            try {
                const response = await http.post('/get_invite_email/' + token);

                if (response.status === 200) {
                    const usernameInput = document.getElementById("user-ipt-singup");
                    this.loading = false
                    this.failOnLoad = false
                    this.inviteEmail = response.data

                    this.$nextTick(() => {
                        usernameInput.focus();
                    })

                }

            } catch (error) {
                if (error.response.status === 400 && error.response.data === "invite_accepted") {
                    this.alertText = "Este convite ja foi aceito. Retorne para a <a href='/login'>página de login</a> e acesse o sistema usando suas suas credenciais!"
                } else {
                    this.alertText = "Não foi possível validar as informações, devido a um token inválido ou expirado. Entre em contato com o administrador da sua unidade para que um novo convite possa ser gerado."
                }

                this.loading = false
                this.failOnLoad = true
            }

        }

        if (this.$route.query.invite) {
            this.singUp = true
            validateToken(this.$route.query.invite)

        }
        // else if (this.$route.query.new_user) {
        //     this.singUp = false
        //     playSound('confirm');

        //     this.$nextTick(() => {
        //         this.$emit('notifySuccess', { message: "<h5 class='fw-bold text-white mb-2'>Bem vindo!</h5><p class='text-white mb-0'>Cadastro realizado com sucesso. Entre com suas credenciais para acessar o sistema.</p>", id: 1 })
        //         const usernameInput = document.getElementById("user-ipt");
        //         usernameInput.value = this.$route.query.new_user.toLowerCase();
        //         usernameInput.focus();
        //     })
        // }

    },

    methods: {

        removeInvalid(event) {
            const targetId = event.target.id;
            if (this.invalidInputs.includes(targetId)) {
                this.invalidInputs.splice(this.invalidInputs.indexOf(targetId), 1);
            }
        },

        isEqualPasswords() {
            const password1 = document.getElementById("pass-ipt-singup")
            const password2 = document.getElementById("pass-ipt2-singup")
            this.passWord = password1.value

            this.equalPasswords = password1.value === password2.value
            const listItem = document.getElementById("equal-passwords-singup")
            let plus8 = false
            let minus1 = false
            let capital = false
            let number = false
            let special = false

            if (this.passWord.length >= 8) {
                plus8 = true
            }

            if (/[a-z]/.test(this.passWord)) {
                minus1 = true
            }

            if (/[A-Z]/.test(this.passWord)) {
                capital = true
            }

            if (/[0-9]/.test(this.passWord)) {
                number = true
            }

            if (/[!@#$%^&*]/.test(this.passWord)) {
                special = true
            }

            let validPassword = (plus8 && minus1 && capital && number && special && this.equalPasswords)


            this.message = `
                <p>Sua senha deve conter ao menos:</p>
                <ul>
                    <li>- 8 caracteres ${plus8 ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li> 
                    <li>- 1 maiúscula ${capital ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
                    <li>- 1 minúscula ${minus1 ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
                    <li>- 1 número ${number ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
                    <li>- 1 caractere especial ${special ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
                    <li id="equal-passwords-singup">- As senhas devem ser iguais ${this.equalPasswords ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
                </ul>
                `

            if (password1.value !== "" && validPassword) {
                listItem.innerHTML = `- As senhas devem ser iguais ${this.equalPasswords ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}`
                return true
            }

            return false
        },

        async singUpUser(submit = false) {

            this.passWord = document.getElementById("pass-ipt-singup").value;
            const usernameInput = document.getElementById("user-ipt-singup");
            const inputName = document.getElementById("name-ipt-singup");
            const inputSurname = document.getElementById("surname-ipt-singup");
            this.invalidInputs = []

            let valid = true
            let validUsername = false
            let validPassword = this.isEqualPasswords()

            // verify username validity, should not contain special characters except _ and -, should not be empty or contains empty spaces
            const re = /^[a-zA-Z0-9_-]+$/

            if (usernameInput.value.trim() === "") {
                this.usernameError = 'Esse campo é obrigatório.'

            } else if (!re.test(usernameInput.value)) {
                this.usernameError = 'Nome de usuário inválido. Não pode conter caracteres especiais, exceto "_" ou "-" nem espaços em branco.'

            } else {
                this.usernameError = null
                validUsername = true
            }

            if (inputName.value.trim() === "") {
                this.invalidInputs.push("name-ipt-singup")
                valid = false
            }

            if (inputSurname.value.trim() === "") {
                this.invalidInputs.push("surname-ipt-singup")
                valid = false
            }

            if (validPassword && valid && validUsername) {
                valid = true

            } else {
                if (!validPassword) {
                    this.invalidInputs.push("pass-ipt-singup")
                    this.invalidInputs.push("pass-ipt2-singup")
                }
                valid = false
                playSound('mistake')
            }

            if (submit && valid) {
                const hashedPass = sha256(this.passWord).toString();

                try {

                    const usernameTaken = await http.get('/check_username/' + usernameInput.value.trim());

                    if (usernameTaken.status === 200 && usernameTaken.data === false) {
                        this.usernameError = 'Nome de usuário em uso. Escolha outro.'
                        this.$emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Nome de usuário em uso!</h5><p class='text-white mb-0'>Por favor, tente novamente!</p>", id: 1 })
                        this.invalidInputs.push("username-ipt-singup")
                        valid = false
                        playSound('mistake')

                    } else {
                        this.loading = true

                        const data = {
                            username: usernameInput.value.trim(),
                            password: hashedPass,
                            name: inputName.value.trim(),
                            surname: inputSurname.value.trim(),
                        }

                        // Get token from query string 
                        const token = this.$route.query.invite

                        const response = await http.post('/accept_invite/' + token, data);

                        if (response.status === 200) {
                            this.loading = false

                            // Go to login page
                            this.singUp = false

                            this.$nextTick(() => {
                                playSound('confirm')
                                this.$emit('notifySuccess', { message: "<h5 class='fw-bold text-white mb-2'>Bem vindo!</h5><p class='text-white mb-0'>Cadastro realizado com sucesso. Entre com suas credenciais para acessar o sistema.</p>", id: 1 })
                                const usernameInput = document.getElementById("user-ipt");
                                usernameInput.value = data.username.toLowerCase();
                                usernameInput.focus();
                            })

                        }
                    }

                } catch (error) {

                    if (error.response) {

                        if (error.response.status === 400 && error.response.data === "username_taken") {

                            playSound('mistake')
                            this.$emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Nome de usuário em uso!</h5><p class='text-white mb-0'>Por favor, tente novamente!</p>", id: 1 })
                            this.invalidInputs.push("username-ipt-singup")
                            valid = false
                            this.usernameError = 'Nome de usuário em uso. Escolha outro.'

                        } else if (error.response.status === 400 && error.response.data === "invite_revoked") {
                            this.alertText = "Não foi possível validar as informações, devido a um token inválido ou expirado. Entre em contato com o administrador da sua unidade para que um novo convite possa ser gerado."
                            this.failOnLoad = true

                        } else if (error.response.status === 400 && error.response.data === "invite_accepted") {
                            this.alertText = "Este convite ja foi aceito. Retorne para a <a href='/login'>página de login</a> e acesse o sistema usando suas suas credenciais!"
                            this.failOnLoad = true

                        } else {
                            this.$emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Erro na solicitação!</h5><p class='text-white mb-0'>Por favor, tente novamente!</p>", id: 2 })
                            playSound('mistake')

                        }
                    } else {
                        this.$emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Erro na solicitação!</h5><p class='text-white mb-0'>Ocorreu um erro inesperado. Tente novamente mais tarde.</p>", id: 2 })
                        playSound('mistake')
                    }


                    this.loading = false
                }

            } else if (submit && !validUsername) {
                this.$emit("notifyError", { message: 'Nome de usuário inválido. Revise os requisitos e tente novamente.', id: 3 })
                playSound('mistake')


            } else if (submit && !validPassword) {
                this.$emit("notifyError", { message: 'Sua senha deve atender aos requisitos de segurança.', id: 4 })
                playSound('mistake')


            } else if (submit && !valid) {
                this.$emit("notifyError", { message: 'Por favor, preencha todos os campos corretamente.', id: 5 })
                playSound('mistake')


            }

        },

    }

};
</script>


<style scoped>
a {
    text-decoration: none;
    font-weight: 200;
    font-size: 1.1rem;
    border-bottom: 0.5px solid white;
    padding-bottom: 2px;
}

a:hover {
    color: aquamarine !important;
    border-bottom: none;
}

.toggle-access {
    margin-top: 1rem;
}

.logo {
    width: 240px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.logo-igzan {
    width: 80px;
}


input {
    font-size: 1rem !important;
    border-radius: 100px;
    height: 54px;
    border-width: 0.15rem;
    background-color: rgba(255, 255, 255, 0.5);
    /* border-color: #dbdbdb; */
}

input:focus {
    background-color: rgba(255, 255, 255, 0.5);
}

button {
    display: block;
    width: 100%;
    margin-top: 2.3rem;
    border-radius: 100px;
    height: 50px;
    font-size: 1rem !important;
}

.main-div {
    max-width: 500px;
}

.form-div {
    position: relative;
}

.form-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(227, 235, 255, 0.9) 100%);
    backdrop-filter: blur(5px);
    z-index: -1;
    border-radius: 7px;
}


.input-group-text {
    width: 95px;
}

.content {
    height: 200px;
}

.logo-img span {
    font-family: 'ubuntu', sans-serif;
    font-size: 2.4rem;
}
</style>