<template>
    <div class="hstack p-2 align-items-start">
        <img :src="getProfilePicture(instructor.profile_picture)" alt="logo" class="img-fluid rounded-circle"
            style="width: 65px;">
        <div class="ms-3">
            <h5>{{ instructor.name }} {{ instructor.surname }}</h5>
            <h6 v-if="coordinator" text-primary class="mb-1 text-primary">Coordenação do curso</h6>
            <h6 v-else class="mb-1 text-primary">Instrutor</h6>
            <p class="mb-0">{{ instructor.curriculum }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "CourseInstructorCard",
    props: ['instructor', 'coordinator'], 

    methods: {
        getProfilePicture(profile_picture) {
            const base_url = window.location.origin;
            return base_url + profile_picture;
        },
    }

}
</script>


<style></style>