import axios from 'axios';
import Cookies from 'js-cookie';


const axiosInstance = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS',
        'Access-Control-Allow-Headers' : 'Origin, Content-Type, Accept, X-Custom-Header, Upgrade-Insecure-Requests'
    }
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const accessToken = localStorage.getItem('token');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const errorType = error.response.data.error;

        // alert(errorType)
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken = Cookies.get('refresh_token');

            if (errorType === 'token_expired' && refreshToken) {

                try {

                    let newAxiosInstance = axios.create({
                        baseURL: '',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    })

                    const response = await newAxiosInstance.post('/auth/refresh', )
                    const newAccessToken = response.data.access_token;
                    localStorage.setItem('token', newAccessToken);

                    if (response.status === 200) {
                        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
                        return axios(originalRequest);
                    }

                    window.location = '/login' + '?redirect=' + window.location.pathname;
                    localStorage.clear();
                    

                } catch (refreshError) {
                    window.location = '/login' + '?redirect=' + window.location.pathname;
                    localStorage.clear();
                }

            } else {
                window.location = '/login' + '?redirect=' + window.location.pathname;
                localStorage.clear();
            } 
        } 

        return Promise.reject(error);

    }
);

export default axiosInstance;