<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,600;0,700;1,600;1,700&family=Montserrat:ital,wght@0,300;0,400;0,700;0,800;0,900;1,300;1,400;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,300;1,400&family=Ubuntu:ital,wght@0,300;0,500;1,300;1,400&display=swap"
    rel="stylesheet">
  <router-view></router-view>
</template>
  
<script>
// import { useStore } from '../store';

export default {
  name: 'App',
}

</script>
  
<style>
#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* Estilize a barra de rolagem */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  /* Largura da barra de rolagem */
}

/* Estilize o trilho da barra de rolagem */
::-webkit-scrollbar-track {
  background-color: transparent;
  /* Cor do trilho */
}

/* Estilize o polegar da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  /* Cor do polegar */
}

/* Estilize o polegar da barra de rolagem quando estiver sendo arrastado */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--bs-link-color);
  /* Cor do polegar em hover */
}

p {
  color: var(--bs-body-color) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: var(--bs-danger) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: var(--bs-warning) !important;
}

.card {
  box-shadow: none !important;
}

.p-default {
  padding: 1.5rem !important;
}

.m-default {
  margin: 1.5rem !important;
}

</style>