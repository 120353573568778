<template>
  <div class="modal" id="WelcomeModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog px-sm-4" style="max-width: 590px; min-width: 350px;">
      <div class="modal-content h-100 p-4 pb-2">
        <div class="modal-header p-0 mb-2">
          <h2 class="modal-title pb-1" id="confirmModalLabel">
            <i class="me-1 ms-0 bi bi-info-circle-fill text-primary"></i>
            Bem vindo!
          </h2>
          <button type="button" class="btn-close" @click="cancelAction" id="closeModal"></button>
        </div>
        <div class="modal-body p-0">
          <p>Seja muito bem vindo ao seu sistema Kahun! Para começar com o pé direito, assista o vídeo abaixo com
            algumas instruções importantes:</p>
          <div class="video-container p-0 m-0 d-flex justify-content-center" id="videoContainer">
            <div class="video">
              <div v-if="loadingVideo" class="video-overlay">
                <button class="btn btn-secondary" type="button" disabled>
                  <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Carregando conteúdo...
                </button>
              </div>
              <div v-if="failLoading && !loadingVideo" class="video-overlay">
                <button class="btn btn-danger" type="button" disabled>
                  <i class="bi me-2 ms-0 bi-exclamation-diamond-fill text-white"></i>
                  Falha ao carregar conteúdo
                </button>
              </div>
              <video controls="controls" controlslist="nodownload" class="m-0 w-100 h-100 my-3" type="video/mp4"
                :src="videoUrl" id="video-element" style="border-radius: 5px;">
              </video>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-end m-0 p-0 py-2">

            <button type="button" class="btn btn-outline-secondary col col-sm-4 m-0" @click="cancelAction">Ok!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import http from "@/services/http";
import { ref, onMounted  } from 'vue';

export default {
  name: 'firstAccessNotesModal',
  mounted() {
    this.getVideoURL();
  },

  setup() {
    const videoUrl = ref(null);
    const loadingVideo = ref(true);
    const failLoading = ref(false);

    onMounted(() => {
      const modal = document.getElementById("WelcomeModal")
      modal.addEventListener("hidden.bs.modal", () => {
        videoUrl.value = null;
      })
    })

    return {
      videoUrl,
      loadingVideo,
      failLoading
    }
  },

  methods: {

    async cancelAction() {
      // close popup
      const modal = Modal.getInstance(document.getElementById("WelcomeModal"))
      modal.hide()

      const data = {
        'first_access': false,
      };

      try {
        const response = await http.post('/user_info_update', data);

        if (response.status === 200) {
          localStorage.removeItem('first_access');
        }

      } catch (error) {
        console.log(error);
      }
    },

    async getVideoURL() {
      let link = 'https://www.dropbox.com/scl/fi/964q2qslw28vphjdzawhw/V-deo-Introdu-o-Sistema-Kahun-V1.1-Final-Feito-com-o-Clipchamp_1716498008074.mp4?rlkey=tai6czx7atrqtnycbdiohtjmu&st=gy5451aq&dl=0';
      // alert(link);

      if (link && link !== "") {
        this.failLoading = false;

        if (link.includes("dropbox.com") && link.includes("dl=0")) {
          link = link.replace("dl=0", "raw=1");
        }

        try {
          // const response = await http.get(`/get-dropbox-resource?url=${encodeURIComponent(link)}`);
          // if (response.status === 200) {
          //     // getting body "url" from response
          //     link = response.data;
          // }

          this.videoUrl = link;

        } catch (error) {
          console.log(error);
          this.failLoading = true
        }

        this.videoUrl = link;
        this.loadingVideo = false;
      }
    },
  }
}

</script>

<style scoped>
.modal-dialog {
  max-width: 450px;
}

textarea {
  height: 100px;
  resize: none;
}

.invalid textarea {
  border: 2px solid var(--bs-danger);
  transition: border-color 0.3s ease;
  animation: shine 0.5s ease-out;
}

.invalid p {
  display: block !important;
  transition: border-color 0.3s ease;
  animation: shine 0.5s ease-out;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  max-height: calc(100vh - 70px);
  position: relative;
}

.video-overlay {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 100%;
  height: 100%;
  z-index: 3;
}
</style>