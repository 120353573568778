<template>
  <header class="app-header">
    <nav class="navbar navbar-expand-lg" :class="{ 'navbar-dark': theme === 'dark' }">
      <div class="container-fluid p-0 header-content">
        <div class="row w-100 d-flex flex-nowrap m-0">
          <!-- Coluna 1 -->

          <div class="col-auto p-0 m-0" style="min-width: 150px; width: fit-content">
            <div class="container d-flex h-100 align-items-center justify-content-start ps-0">

              <div v-if="theme === 'light'">
                <img src="../../assets/images/logos/symbol_light.png" alt="" class="logo mx-1 d-none d-xl-block" v-if="isMenuVisible === false" />
                <img src="../../assets/images/logos/symbol_light.png" alt="" class="logo mx-1 d-xl-none"/>
              </div>
              
              <div v-if="theme === 'dark'">
                <img src="../../assets/images/logos/symbol_dark.png" alt="" class="logo mx-1 d-none d-xl-block" v-if="isMenuVisible === false" />
                <img src="../../assets/images/logos/symbol_dark.png" alt="" class="logo mx-1 d-xl-none"/>
              </div>

              <button class="btn p-0 menu-icon d-none d-xl-block" type="button" @click="hideMenu" id="sidebar-toggle">
                <i class="ti ti-menu-2"></i>
              </button>
              <button class="btn p-0 menu-icon d-block d-xl-none" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="ti ti-menu-2"></i>
              </button>
            </div>
          </div>

          <!-- Coluna 2 -->
          <div class="col">
            <div class="d-flex align-items-center justify-content-center h-100">
            <!-- Show just in next deploy -->
              <input v-if="false" type="text" class="form-control d-sm-block d-none" placeholder="Pesquisa"
                style="border-radius: 100px" />
            </div>
          </div>
          <!-- Fim da coluna 2 -->


          <!-- Coluna 3 -->
          <div class="col-auto justify-content-end" style="min-width: 150px; width: fit-content">
            <div class="container d-flex h-100 p-0 justify-content-end">
              <button v-if="false" class="btn p-0 menu-icon d-sm-none" type="button">
                <i class="ti ti-search"></i>
              </button>
              <!-- Show just in next deploy -->
              <button v-if="false" class="btn p-0 menu-icon" type="button" @click="showMenuState">
                <span class="badge bg-danger badge-number">9</span>
                <i class="ti ti-bell-ringing"></i>
              </button>
              <button class="btn p-0 menu-icon" type="button" id="moon" :class="{ 'd-none': theme === 'dark' }"
                @click="changeTheme">
                <i class="ti ti-moon"></i>
              </button>
              <button class="btn p-0 menu-icon" type="button" id="sun" :class="{ 'd-none': theme === 'light' }"
                @click="changeTheme">
                <i class="ti ti-sun"></i>
              </button>
              <div class="navbar-collapse justify-content-end px-0" id="navbarNav" style="max-width: 53px;">
                <ul class="navbar-nav align-items-center justify-content-center">
                  <li class="nav-item dropdown">
                    <a class="nav-link nav-icon-hover px-0" id="drop2" style="margin-right: 5px;">
                      <img :src="getProfilePicture()" alt="" width="35" height="35"
                        class="rounded-circle" id="profile-picture" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up dropdown-content">
                      <div class="message-body">
                        <a class="d-flex align-items-center gap-2 dropdown-item" @click="this.$emit('open-profile')">
                          <i class="ti ti-user fs-6"></i>
                          <p class="mb-0 fs-3">Meu perfil</p>
                        </a>
                        <a class="d-flex align-items-center gap-2 dropdown-item" @click="this.$emit('openBugReport')">
                          <i class="ti ti-bug fs-6"></i>
                          <p class="mb-0 fs-3">Bug report</p>
                        </a>
                        <a class="d-flex align-items-center gap-2 dropdown-item" @click="this.$emit('openSystemAbout')">
                          <i class="ti ti-help fs-6"></i>
                          <p class="mb-0 fs-3">Sobre o sistema</p>
                        </a>
                        <hr  class="mx-2 my-0"/>
                        <a class="d-flex align-items-center gap-2 dropdown-item logout-button" @click="this.$emit('logout')">
                          <i class="bi bi-box-arrow-left fs-5"></i>
                          <p class="mb-0 fs-3">Logout</p>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Fim da coluna 3 -->
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
// import { ref } from 'vue';

export default {
  name: "AppHeader",
  emits: ['change-theme', 'hide-menu', 'open-profile', 'openSystemAbout', 'logout', 'openBugReport'],
  props: {
    theme: String,
    isMenuVisible: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    
    changeTheme() {
      this.$emit('change-theme');
    },
    hideMenu() {
      this.$emit("hide-menu");
    },

    showMenuState() {
      alert(localStorage.getItem('showMenu'));
    },

    getProfilePicture() {
      // TODO: import enviorement variables
      const base_url = window.location.origin;
      return base_url + '/api/profile_picture/' + localStorage.getItem("username");
    },

  },
};
</script>

<style scoped>
i {
  font-size: 1.4rem;
  color: var(--bs-secondary-text);
}

p {
  color: var(--bs-secondary-text);
}

input {
  max-width: 500px;
  border-radius: 100px;
  color: var(--bs-emphasis-color) !important;
  border-width: 0.1rem;
}

input:focus {
  border-width: 0.18rem;
}

.header-content {
  max-width: 2000px !important;
}

#offcanvasExample {
  width: 275px;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--bs-secondary-text);
  opacity: 0.5;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--bs-secondary-text);
}

.menu-icon {
  border-radius: 100px;
  width: 40px;
  height: 40px;
  border: none;
  transition: color 0.3s ease-in-out;
  align-self: center;
  padding: 0.5rem !important;
}

.menu-icon {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
}

.menu-icon:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.3);
  color: var(--bs-primary);
}

.dropdown-item:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.2) !important;
  color: var(--bs-emphasis-color) !important;
}

#sun:hover {
  background-color: rgba(var(--bs-warning-rgb), 0.3);
  color: yellow;
}

.col-fixed {
  min-width: fit-content;
  max-width: fit-content;
}

.message-body a:hover {
  background-color: rgba(var(--bs-light-primary-rgb), 0.4);
}

.badge-number {
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
  position: absolute;
  top: -7px;
  right: -23px;
  transform: translate(50%, -50%);
  border-radius: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  user-select: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 0px;
  top: 70px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.logo {
  height: 50px;
  width: auto;
}

.logout-button:hover > p {
  color: var(--bs-danger) !important;
}

.logout-button:hover > i {
  color: var(--bs-danger) !important;
}

</style>
