import { Howl } from 'howler';

export const playSound = (mood) => {
    let sound = null;

      if (mood === 'confirm') {
        sound = new Howl({
          src: [require('@/../assets/sounds/confirm.wav')]
        })
      } else if (mood === 'achivement') {
        sound = new Howl({
          src: [require('@/../assets/sounds/achivement.wav')]
        })
      } else if (mood === 'notification') {
        sound = new Howl({
          src: [require('@/../assets/sounds/notification.wav')]
        })
      } else if (mood === 'mistake') {
        sound = new Howl({
          src: [require('@/../assets/sounds/mistake.wav')]
        })
      } else if (mood === 'somethingWeird') {
        sound = new Howl({
          src: [require('@/../assets/sounds/something-weird.wav')]
        })
      } else if (mood === 'accessDenied') {
        sound = new Howl({
          src: [require('@/../assets/sounds/access-denied.wav')]
        })
      } else if (mood === 'error') {
        sound = new Howl({
          src: [require('@/../assets/sounds/error.wav')],
          volume: 0.7
        })
      } else if (mood === 'something') {
        sound = new Howl({
          src: [require('@/../assets/sounds/something.wav')],
        })
      } else if (mood === 'intro') {
        sound = new Howl({
          src: [require('@/../assets/sounds/intro.wav')],
          volume: 0.8
        })
      } else if (mood === 'login') {
        sound = new Howl({
          src: [require('@/../assets/sounds/login.wav')],
        })
      }

      if (sound) {
        sound.play();
      }

    }