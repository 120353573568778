<template>
    <div class="modal fade" id="changeUnitModal" tabindex="-1" aria-labelledby="changeUnitModalLabel" aria-hidden="true">
        <div class="modal-dialog px-sm-4 p-3">
            <div class="modal-content px-1">
                <div class="modal-header p-4 pb-3">
                    <h3 class="modal-title" id="changeUnitModalLabel">Mudar unidade</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-floating mb-2">
                        <select class="form-select" id="floatingSelectBusinessUnit" aria-label="Floating label select example">
                            <option v-for="unit in accessUnits" :key="unit.id" :value="unit.id">{{ unit.nickname }}</option>
                        </select>
                        <label for="floatingSelectBusinessUnit">Selecione uma unidade</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-warning" @click="changeUnit"><i class="bi bi-arrow-left-right me-2"></i>Trocar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'changeUnitModal',
    props: ['accessUnits'],
    emits: ['change-unit'],

    methods: {
        changeUnit() {
            const unit = document.getElementById('floatingSelectBusinessUnit').value
            this.$emit('change-unit', unit)
        }
    }
}

</script>

.modal-dialog {
z-index: 10000;
}
<style scoped></style>