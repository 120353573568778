<template>
    <div v-if="bookmark && !bookmark.course_visible">
        <div class="container container-fluid d-flex align-items-center justify-content-center flex-column p-5"
            style="max-width: 650px;">
            <h2 class="text-center display-3">Ops!</h2>
            <h5 class="text-center my-4">O Treinamento que você está procurando não existe mais ou não está disponível
                no momento. Volte para
                a lista de treinamentos e encontre um outro e tente novamente.</h5>
            <a href="/learning" class="btn btn-lg btn-primary mb-2" role="button">Ir para a lista de treinamentos</a>
            <img src="../../assets/images/images/404 Error with a cute animal-rafiki.svg">
        </div>
    </div>

    <div v-else>

        <div class="class-body p-4 pt-3">

            <div class="course-class p-0" id="courseClass">

                <div v-if="classInfo && classInfo.visible == false">
                    <div class="container container-fluid d-flex align-items-center justify-content-center flex-column p-5"
                        style="max-width: 650px;">
                        <h2 class="text-center display-3">Ops!</h2>
                        <h5 class="text-center my-4">Parece que a aula que você está tentando acessar não está
                            disponível no
                            momento.
                            Para continuar seu treinamento, selecione uma outra aula para assistir.</h5>
                        <img src="../../assets/images/images/404 Error with a cute animal-rafiki.svg">
                    </div>
                </div>
                <div v-else>
                    <div class="video-container p-0 m-0 d-flex justify-content-center" id="videoContainer">
                        <div class="video">
                            <div v-if="loadingVideo" class="video-overlay">
                                <button class="btn btn-secondary" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm me-2" role="status"
                                        aria-hidden="true"></span>
                                    Carregando aula...
                                </button>
                            </div>
                            <div v-if="failLoading && !loadingVideo" class="video-overlay">
                                <button class="btn btn-danger" type="button" disabled>
                                    <i class="bi me-2 ms-0 bi-exclamation-diamond-fill text-white"></i>
                                        Falha ao carregar aula
                                </button>
                            </div>
                            <video controls="controls" controlslist="nodownload" class="m-0 w-100 h-100"
                                type="video/mp4" :src="videoUrl" id="video-element">
                            </video>
                        </div>
                    </div>
                    <ul class="nav nav-pills mb-3 mt-3 px-2 row flex-nowrap row-cols-auto g-0" id="pills-tab"
                        role="tablist">
                        <li class="nav-item col" role="presentation">
                            <button class="nav-link active" id="pills-about-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-about" type="button" role="tab" aria-controls="pills-about"
                                aria-selected="true">Sobre</button>
                        </li>
                        <li class="nav-item col" role="presentation">
                            <button class="nav-link" id="pills-faculty-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-faculty" type="button" role="tab" aria-controls="pills-faculty"
                                aria-selected="false">Corpo docente</button>
                        </li>
                        <li class="nav-item col" role="presentation">
                            <button class="nav-link" id="pills-notes-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-notes" type="button" role="tab" aria-controls="pills-notes"
                                aria-selected="false">Anotações</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent" style="min-height: 300px;">
                        <div class="tab-pane fade show  p-3 px-4 active" id="pills-about" role="tabpanel"
                            aria-labelledby="pills-about-tab" tabindex="0">
                            <h2 class="m-0">Sobre este treinamento</h2>
                            <p class="my-3" style="text-align: justify;" v-html="classInfo.course_description"></p>
                        </div>
                        <div class="tab-pane fade p-3 px-4" id="pills-faculty" role="tabpanel"
                            aria-labelledby="pills-faculty-tab" tabindex="0">
                            <h2 class="m-0">Corpo docente</h2>
                            <div class="my-3">
                                <CourseInstructorCard v-if="classInfo.course_coordinator"
                                    :instructor="classInfo.course_coordinator" :coordinator="true">
                                </CourseInstructorCard>
                                <hr v-if="classInfo.course_instructors &&
        classInfo.course_instructors.length > 0 &&
        classInfo.course_instructors[0].id != classInfo.course_coordinator.id"
                                    style="border: 1px solid var(--bs-info-bg-subtle);" />
                                <div v-for="instructor in classInfo.course_instructors" :key="instructor.id"
                                    class="mt-3">
                                    <CourseInstructorCard v-if="instructor.id != classInfo.course_coordinator.id"
                                        :instructor="instructor"></CourseInstructorCard>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade p-3 px-4 " id="pills-notes" role="tabpanel"
                            aria-labelledby="pills-notes-tab" tabindex="0">
                            <h2 class="m-0">Minhas anotações</h2>
                            <h6 class="mt-2 text-primary"> <i class="bi bi-info-circle"></i> Apenas você {{
        classInfo.user_notes
            &&
            classInfo.user_notes.length > 0 ? 'pode' : 'poderá' }} ver essas anotações</h6>
                            <div class="w-100 d-flex justify-content-center my-3">
                                <div class="row align-items-center w-100 g-2">
                                    <div class="col-sm-7 col-xl-10">
                                        <textarea @input="resize()" type="text" class="form-control form-control-lg"
                                            :placeholder="`Criar anotação em ${videoRoundedTime}`" aria-label="Username"
                                            id="commentInput" ref="textarea"
                                            style="border-radius: 5px; min-width: none;"></textarea>
                                    </div>
                                    <div class="col-sm-5 col-xl-2">
                                        <button class="btn btn-lg btn-outline-secondary w-100 p-2"
                                            style="min-width: 100px;" @click="addComment">Adicionar</button>
                                    </div>
                                </div>
                            </div>
                            <div v-for="comment in classInfo.user_notes" :key="comment.id">
                                <div class="d-flex flex-row flex-nowrap card p-2 my-1 commentcard"
                                    :id="'comment-' + comment.id" @click="goToVideoTime(comment.time)">
                                    <h6><span class="badge bg-secondary commentbadge px-3 m-1">{{
        formatTime(comment.time)
    }}</span>
                                    </h6>
                                    <p class="px-3 my-1 fs-4 w-100" :id="'commentText-' + comment.id"
                                        v-html="comment.notes">
                                    </p>
                                    <textarea class="w-100 h-100 mx-2 form-control fs-4 editComment d-none"
                                        @input="resizeEdit($event)" :id="'editComment-' + comment.id"></textarea>

                                    <div class="comment-buttons d-flex flex-row flex-nowrap"
                                        :id="'comment-buttons-' + comment.id" @click.prevent.stop>
                                        <span data-v-6a54ccc6=""
                                            class="badge rounded-pill text-bg-secondary deletecomment mx-1 d-flex justify-content-center align-items-center d-none"
                                            @click="deleteComment(comment.id)">
                                            <i data-v-6a54ccc6="" class="bi bi-trash align-middle"></i>
                                            <span class="tooltiptext">Excluir</span>
                                        </span>
                                        <span data-v-6a54ccc6=""
                                            class="badge rounded-pill text-bg-secondary editcomment d-flex justify-content-center align-items-center d-none"
                                            @click="editComment(comment.id)">
                                            <i data-v-6a54ccc6="" class="bi bi-pencil align-middle"></i>
                                            <span class="tooltiptext">Editar</span>
                                        </span>
                                    </div>
                                    <div class="save-comment-changes d-flex flex-column align-items-start p-1 d-none"
                                        :id="'save-comment-changes-' + comment.id" @click.prevent.stop>
                                        <button class="btn btn-md btn-outline-success w-100"
                                            @click="saveCommentChanges(comment.id)">Salvar</button>
                                        <button class="btn btn-md btn-outline-danger w-100 my-1"
                                            @click="editComment(comment.id)">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-md-block p-0 pb-5 chapters fixed-top" id="courseMenu">
                <div class="p-3 sticky-top hstack justify-content-between chaptersHeader">
                    <div>
                        Conteúdo do curso
                    </div>
                    <div>
                        <i class="bi bi-x-lg close-menu" @click="toggleCourseMenu"></i>
                    </div>
                </div>
                <div class="chapters-wrapper p-0" id="chapters-wrapper-1">
                    <div class="m-0 p-0" v-for="module in modules" v-bind:key="module.id">
                        <div v-if="module.lessons.length > 0">
                            <CourseModuleCard ref="moduleCard" :moduleData="module" :bookmark="bookmark"
                                :lessonData="classInfo" :index="1" @update-completed="updateCompleted"
                                @notifyError="this.$emit('notifyError', $event)" />
                            <hr class="m-0 p-0" />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center m-3 text-primary" :class="{ 'd-none': !loading }">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>

        </div>

        <!-- Middle plus screen button -->
        <button class="d-none btn btn-lg btn-secondary myBtn" id="courseMenuList" type="button"
            @click="toggleCourseMenu">
            <i class="bi bi-list-ul">
                <p class="d-inline text-white ms-2 d-none">
                    Conteúdo do curso
                </p>
            </i>
        </button>

        <!-- Middle minus screen button -->
        <button class="d-block d-md-none btn btn-sm btn-secondary myBtn mobileBtn" type="button"
            data-bs-toggle="offcanvas" data-bs-target="#offcanvasCourseMenu" aria-controls="offcanvasCourseMenu">
            <i class="bi bi-list-ul">
                <p class="d-inline text-white ms-2 d-none">
                    Conteúdo do curso
                </p>
            </i>
        </button>

        <!-- off-canvas -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasCourseMenu"
            aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header chaptersHeader">
                <div class="w-100 d-flex justify-content-between">
                    Conteúdo do curso
                </div>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
            </div>
            <div class="d-flex justify-content-center m-3 text-primary" :class="{ 'd-none': !loading }">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="offcanvas-body p-0" id="chapters-wrapper-2">
                <div class="chapters-wrapper p-0 pb-5">
                    <div class="m-0 p-0" v-for="module in modules" v-bind:key="module.id">
                        <div v-if="module.lessons.length > 0">
                            <CourseModuleCard ref="moduleCard" :moduleData="module" :bookmark="bookmark"
                                :lessonData="classInfo" @notifyError="this.$emit('notifyError', $event)" :index="2"
                                @update-completed="updateCompleted" />
                            <hr class="m-0 p-0" />
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- Fim do off-canvas -->

    </div>
</template>

<script>
import { directive } from 'vue-tippy'
import { toRef, ref } from 'vue';
import http from "@/services/http"
import CourseModuleCard from './CourseModuleCard.vue';
import CourseInstructorCard from './CourseInstructorCard.vue';
import { onMounted } from "vue";


export default {
    name: "CourseClass",
    emits: ['notifyError', 'hide-menu'],
    components: {
        CourseModuleCard,
        CourseInstructorCard
    },

    data() {
        return {
            modules: [],
            classInfo: {},
            loading: true,
            bookmark: null,
            classWatched: false,
        }
    },


    setup(props, ctx) {
        const isMenuVisible = toRef(localStorage, 'showMenu');
        const videoCurrentTime = ref(0.0);
        const videoRoundedTime = ref('00:00');
        const videoDuration = ref(0);
        const videoUrl = ref(null);
        const loadingVideo = ref(true);
        const failLoading = ref(false);

        const connectionFail = () => {
            ctx.emit('notifyError', '<h5 class=\'fw-bold text-white mb-2\'>Falha ao conectar!</h5><p class=\'text-white mb-0\'>Verifique a sua conexão com a rede</p>', 1);
        }

        const hideMenu = () => {
            ctx.emit('hide-menu')
        }

        const toggleCourseMenu = () => {
            const courseMenu = document.getElementById('courseMenu');
            const courseClass = document.getElementById("courseClass");
            const courseMenuList = document.getElementById("courseMenuList");
            courseMenu.classList.toggle('hidden');
            courseClass.classList.toggle('theater');
            courseMenuList.classList.toggle('d-none');
        }

        const formatTime = (totalSeconds) => {
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = Math.floor(totalSeconds % 60);

            if (hours < 1) {
                const formattedMinutes = String(minutes).padStart(2, '0');
                const formattedSeconds = String(seconds).padStart(2, '0');
                return `${formattedMinutes}:${formattedSeconds}`;
            } else {
                const formattedHours = String(hours).padStart(2, '0');
                const formattedMinutes = String(minutes).padStart(2, '0');
                const formattedSeconds = String(seconds).padStart(2, '0');
                return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            }
        }

        onMounted(() => {

            try {
                const offcanvasElement = document.getElementById('offcanvasCourseMenu');
                const closeButton = offcanvasElement.querySelector('.btn-close');
                const video = document.getElementById('video-element');

                video.onloadedmetadata = function () {
                    // console.log(video.duration)
                    videoDuration.value = video.duration;
                }

                video.addEventListener('timeupdate', () => {
                    videoRoundedTime.value = formatTime(videoCurrentTime.value);
                })

                offcanvasElement.addEventListener('hide.bs.offcanvas', function () {
                    closeButton.click();
                });

            } catch (error) {
                console.log(error)
            }

        })

        return {
            isMenuVisible: isMenuVisible.value,
            connectionFail,
            hideMenu,
            toggleCourseMenu,
            videoRoundedTime,
            formatTime,
            videoCurrentTime,
            videoUrl,
            loadingVideo,
            failLoading
        }
    },

    mounted() {

        if (localStorage.getItem('showMenu') === null || localStorage.getItem('showMenu') === 'true') {
            const hideMenuBtn = document.getElementById("sidebar-toggle");
            hideMenuBtn.click();
        }

        const courseSlug = this.$route.params.course;
        const currentLesson = this.$route.params.lesson;
        const video = document.getElementById('video-element');

        const getModules = async () => {
            try {
                const { data } = await http.get('/modules/' + courseSlug);
                this.modules = data['modules'];
                this.bookmark = data['bookmark'];
                this.loading = false;

                if (this.bookmark.timestamp > 0 && this.bookmark.lesson == currentLesson) {
                    video.currentTime = this.bookmark.timestamp;
                }


            } catch (error) {
                this.connectionFail();
                setTimeout(() => {
                    getModules();
                }, 5000);
            }
        }

        let bookmarkTimeout = null;

        const updateBookmark = () => {
            this.registerBookMark(video.currentTime);

            if (bookmarkTimeout) {
                clearTimeout(bookmarkTimeout);
            }

            bookmarkTimeout = setTimeout(() => {
                updateBookmark();
            }, 10000);

        }

        if (video) {
            video.addEventListener('play', () => {
                updateBookmark();
            })

            video.addEventListener('pause', () => {
                if (bookmarkTimeout) {
                    clearTimeout(bookmarkTimeout);
                }
            })

            video.addEventListener('timeupdate', () => {
                this.videoCurrentTime = video.currentTime;

                if (video.currentTime / video.duration >= 0.90 &&
                    video.duration > 0 && !this.classWatched) {
                    this.registerBookMark(video.currentTime, true)
                    this.classWatched = true
                    const currentModule = this.modules.find(module => module.id == this.classInfo.module)
                    const currentLesson = currentModule.lessons.find(lesson => lesson.id == this.classInfo.id)
                    currentLesson.completed = true
                }
            })

            video.addEventListener('ended', () => {
                this.goToNextClass();
            });

        }

        this.getLessonInfo();
        getModules();

    },

    watch: {
        modules(to) {
            if (Array.isArray(to) && to.length > 0) {
                this.$nextTick(() => {
                    const currentLessonCard1 = document.getElementById('current-lesson-1');
                    const currentLessonCard2 = document.getElementById('current-lesson-2');

                    const chaptersWrapper1 = document.getElementById('courseMenu');
                    const chaptersWrapper2 = document.getElementById('chapters-wrapper-2');

                    if (currentLessonCard1) {
                        currentLessonCard1.scrollIntoView({ block: 'start' });
                        chaptersWrapper1.scrollBy({ top: -120 });
                    }

                    if (currentLessonCard2) {
                        currentLessonCard2.scrollIntoView({ block: 'start' });
                        chaptersWrapper2.scrollBy({ top: -70 });
                    }
                })
            }
        },

        'classInfo.url'() {
            this.getVideoURL();
        },


    },

    directives: {
        tippy: directive,
    },

    methods: {

        async getVideoURL() {
            let link = this.classInfo.url;
            // alert(link);

            if (link && link !== "") {
                this.failLoading = false;

                if (link.includes("dropbox.com") && link.includes("dl=0")) {
                    link = link.replace("dl=0", "raw=1");
                }

                // try {
                //     const response = await http.get(`/get-dropbox-resource?url=${encodeURIComponent(link)}`);
                //     if (response.status === 200) {
                //         // getting body "url" from response
                //         link = response.data;
                //     }
                // } catch (error) {
                //     console.log(error);
                //     this.failLoading = true
                // }

                this.videoUrl = link;
                this.loadingVideo = false;
            }
        },

        notifyError(message, id) {
            this.$emit('notifyError', { message, id });
        },

        goToVideoTime(time) {
            const video = document.getElementById('video-element');
            video.currentTime = time;
            this.videoCurrentTime = time;
        },

        async registerBookMark(timestamp, concluded, lessonId) {
            // console.log(`timestamp: ${timestamp}, concluded: ${concluded}`);

            const lesson = lessonId ? lessonId : this.$route.params.lesson;

            const json = {
                'timestamp': timestamp,
                'completed': concluded
            }
            try {
                await http.post('/bookmark/' + lesson, json);

            } catch (error) {
                this.connectionFail();

                setTimeout(() => {
                    this.registerBookMark();
                }, 5000);
            }
        },

        async goToNextClass() {
            if (this.classInfo.next_class !== null) {

                await this.registerBookMark(0.0, undefined, this.classInfo.next_class);
                window.location.href = '/learning/' + this.$route.params.course + '/lecture/' + this.classInfo.next_class;
            }
        },

        async updateCompleted({ lessonId, completed }) {
            await this.registerBookMark(undefined, completed, lessonId);
            if (lessonId != this.$route.params.lesson) {
                this.registerBookMark(this.videoCurrentTime);
            }
        },

        async getLessonInfo() {
            const lessonId = this.$route.params.lesson;
            // alert(this.nextClass.value); // Certifique-se de que nextClass tenha um valor aqui
            try {
                const { data } = await http.get('/lesson/' + lessonId);
                this.classInfo = data;
                console.log(this.classInfo);

            } catch (error) {
                this.connectionFail();
                setTimeout(() => {
                    this.getLessonInfo(); // Use `this` para chamar o método corretamente
                }, 5000);
            }
        },

        async addComment() {
            // alert(this.videoCurrentTime)
            const commentInput = document.getElementById('commentInput');
            if (commentInput.value.trim() === '') {
                return;
            }

            try {
                const data = {
                    'lesson': this.classInfo.id,
                    'note': commentInput.value.replace(/\n/g, '<br>'),
                    'time': this.videoCurrentTime || 0.0
                }
                const response = await http.post('/lesson_notes/' + this.classInfo.id, data)
                if (response.status === 200) {
                    commentInput.value = ''
                    this.getLessonInfo();
                    this.resize();
                }
            } catch (error) {
                this.notifyError('Erro ao adicionar comentário:' + error, 1);
            }
        },

        async deleteComment(id) {

            const parentElement = document.getElementById('comment-' + id);
            // alert('comment-' + id);

            try {
                parentElement.classList.add('d-none');
                const response = await http.delete('/update_lesson_notes/' + id)

                if (response.status === 200) {
                    this.getLessonInfo();
                }
            } catch (error) {
                this.notifyError('Erro ao deletar comentário:' + error, 1);
                parentElement.classList.remove('d-none');
            }

        },

        editComment(id) {
            const commentText = document.getElementById('commentText-' + id); // Obtenha o elemento de texto do comentário
            const commentEdit = document.getElementById('editComment-' + id); // Obtenha o elemento de edição do comentário
            const commentButtons = document.getElementById('comment-buttons-' + id);
            const commentSave = document.getElementById('save-comment-changes-' + id);

            commentText.classList.toggle('d-none');
            commentEdit.classList.toggle('d-none');
            commentButtons.classList.toggle('d-none');
            commentSave.classList.toggle('d-none');

            commentEdit.value = commentText.innerHTML.replace(/<br>/g, '\n');
            commentEdit.style.minHeight = "18px";
            commentEdit.style.minHeight = commentEdit.scrollHeight + "px";
            commentEdit.focus();
        },

        async saveCommentChanges(id) {
            const commentText = document.getElementById('commentText-' + id);
            const note = document.getElementById('editComment-' + id).value.replace(/\n/g, '<br>')

            if (note.trim().replace(/<br>/g, '') === '') {
                this.editComment(id);
                return
            }

            const data = {
                'note': document.getElementById('editComment-' + id).value.replace(/\n/g, '<br>'),
            }

            try {
                const response = await http.put('/update_lesson_notes/' + id, data)

                if (response.status === 200) {
                    commentText.innerHTML = data['note'];
                    this.editComment(id);
                    this.getLessonInfo();
                }
            } catch (error) {
                this.notifyError('Erro ao editar comentário:' + error, 1);
            }
        },

        resize() {
            let element = this.$refs['textarea']

            element.style.minHeight = "18px";
            element.style.minHeight = element.scrollHeight + "px";
        },

        resizeEdit(event) {
            const textarea = event.target;
            textarea.style.minHeight = "18px";
            textarea.style.minHeight = `${textarea.scrollHeight}px`;
        },

    }
}

</script>

<style scoped>
::-webkit-scrollbar {
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(191, 191, 191, 0.5);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(191, 191, 191, 1);
}


#commentInput {
    resize: none;
    overflow: auto;
    height: 35px;
    max-height: 180px;
}

.editComment {
    resize: none;
    overflow: hidden;
    border: 0px;
    box-shadow: none;
}


textarea::-webkit-scrollbar {
    width: 5px;
}

textarea::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 50px;
}

textarea::-webkit-scrollbar-thumb:hover {
    background-color: var(--bs-link-color);
}

#pills-tab {
    border-bottom: 0.5px solid var(--bs-border-color-translucent);
    overflow-x: auto;
    overflow-y: hidden;
    min-width: 100%;
}

#pills-tab .nav-link {
    color: var(--bs-secondary) !important;
}

#pills-tab .nav-item .active {
    color: var(--bs-primary) !important;
    font-weight: 600 !important;
    background-color: transparent;
    border-bottom: 3px solid var(--bs-primary);
    border-radius: 0;
}

.scrollable {
    overflow-x: auto;
}

h2 {
    font-weight: 800;
}

.chaptersHeader {
    height: 50px;
    border-bottom: 1px solid #dee2e6;
    color: var(--bs-dark);
    background-color: var(--bs-body-bg);
    color: var(--bs-emphasis-color);
}

#offcanvasCourseMenu {
    width: 350px;
}

.offcanvas-header {
    height: 50px;
}

.close-menu {
    cursor: pointer;
    font-size: 1.2rem;
}

.close-menu:hover {
    color: var(--bs-primary);
}

.myBtn {
    z-index: 1000;
    position: fixed;
    top: 130px;
    right: 0px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px 0 0 10px;
}

.myBtn p {
    font-style: normal;
}

.myBtn:hover p {
    display: inline !important;
}

.video {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    max-height: calc(100vh - 70px);
    position: relative;
}

.card {
    border-radius: 0px;
}

.card-body {
    border: 1px solid #dee2e6;
}

.chapters {
    overflow-y: auto;
    width: 350px;
    top: 70px;
    left: auto;
    height: calc(100vh - 70px);
    z-index: 5 !important;
    background-color: var(--bs-body-bg);
}

.chapters hr {
    color: rgba(var(--bs-secondary-rgb), 0.5);
}

.chapters-wrapper {
    overflow: hidden;
}

.hidden {
    display: none !important;
}


@media (min-width: 768px) {
    .course-class {
        margin-right: 350px;
    }
}

@media (max-width: 768px) {
    .myBtn:not(.mobileBtn) {
        display: none !important;
    }
}

.theater {
    margin-right: 0px !important;
}

.theater>.video-container {
    max-height: calc(100vh - 100px) !important;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
}

.commentcard {
    border-radius: 5px;
}

.commentbadge {
    font-size: small !important;
}

.editcomment,
.deletecomment {
    width: 35px;
    height: 35px;
    position: relative;
    display: inline-block;
}

.tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 7.5px;
    position: absolute;
    z-index: 1;
    top: 125%;
    /* alterado de bottom para top */
    left: 50%;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    /* alterado de top para bottom */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
    /* alterado a ordem das cores */
}

.editcomment:hover {
    background-color: rgba(var(--bs-success-rgb), 0.8) !important;

    .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
}

.deletecomment:hover {
    background-color: rgba(var(--bs-danger-rgb), 0.8) !important;

    .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
}


.commentcard:hover {
    background-color: rgba(var(--bs-secondary-rgb), 0.1);
    cursor: pointer;

    .commentbadge {
        background-color: var(--bs-primary) !important;
    }

    .editcomment {
        display: flex !important;
    }

    .deletecomment {
        display: flex !important;
    }
}

.video-overlay {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 100%;
    height: 100%;
    z-index: 3;
}
</style>