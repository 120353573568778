<template>
  <div class="login-form-container">
    <div class="overlay p-5">
      <RecoveryForm class="mx-auto" @notifyError="notifyError" />
    </div>
  </div>
</template>

<script>
import RecoveryForm from "../components/RecoveryForm.vue"
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'RecoveryPage',
  components: {
    RecoveryForm  
  },
  emits: ['notifyError'],
  setup() {
    const notifyError = ({ message, id }) => {
      toast.error(message, {
        transition: toast.TRANSITIONS.SLIDE,
        autoClose: 5000,
        toastId: id,
        theme: 'colored',
        dangerouslyHTMLString: true,
        style: {
          theme: 'dark',
          opacity: '1',
          userSelect: 'initial',
          top: '35px',
        }
      }); // ToastOptions
    };

    return {
      notifyError
    }

  }
}

</script>


<style scoped>
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  text-align: center;
  min-height: 100vh;
}

.login-form-container {

  min-height: 100vh;
  height: 100%;
  background: linear-gradient(90deg, rgba(var(--bs-primary-rgb), 0.75) 0%, rgba(var(--bs-success-rgb), 0.5) 100%), url(../../assets/images/backgrounds/login_background.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

</style>