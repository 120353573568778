<template>
    <div class="col main-div">
        <div class="pt-4 px-5 shadow-lg form-div">
            <div class="brand-logo d-flex align-items-center justify-content-center logo-img mb-4 mt-3">
                <div class="d-flex align-items-center no-wrap">
                    <img src="../../assets/images/logos/kahun_logo_light.png" alt="petcon" class="img-fluid"
                        style="height: 60px; width: auto;" />
                </div>
            </div>
            <div v-if="loading">
                <div class="spinner-border text-primary mb-2" role="status"></div>
                <h5>Por favor, aguarde...</h5>
                <p>Estamos validando as informações</p>
            </div>
            <div v-else>
                <div v-if="failOnLoad">
                    <div class="alert alert-danger text-start fs-3 mb-3" role="alert"><p>Não foi possível validar as
                        informações, devido a um token inválido ou expirado. Você poderá solicitar uma nova recueração
                        de conta <a class="link-danger" href="/login?recover=true">clicando aqui</a>.</p>
                    </div>
                </div>
                <div v-else>
                    <form class="pass-div mt-2" id="pass-div" @submit.prevent="login">
                        <input type="password" class="form-control mb-3" placeholder="Nova senha" aria-label="Password1"
                            aria-describedby="basic-addon1" id="user-ipt" @input="updatePasswordValue()">
                        <input type="password" class="form-control mb-3" placeholder="Repetir senha"
                            aria-label="Password2" aria-describedby="basic-addon2" id="pass-ipt"
                            @input="isEqualPasswords">
                        <div>
                            <div class="alert alert-info text-start fs-2 mb-3" role="alert">
                                <p v-html="message"></p>
                            </div>
                            <button class="btn btn-primary btn-lg mb-2 mt-2" @click.prevent="updatePasswordValue(true)">Recuperar
                                conta</button>
                        </div>
                    </form>
                </div>
            </div>

            <img src="../../assets/images/igzan_login.png" alt="logo" class="mb-4 mt-3 logo-igzan">
        </div>
    </div>
</template>

<script>
import auth from "@/services/auth";
import sha256 from "crypto-js/sha256";
import { ref } from 'vue'


export default {
    name: "RecoveryForm",
    setup() {
        const recoveryMode = ref(false);
        const sentRecovery = ref(false);
        const token = ref(null);
        const passWord = ref('');
        const confirmPassword = ref('');
        const equalPasswords = ref(false);
        const loading = ref(true)
        const failOnLoad = ref(true)
        const message = ref(`
        <p>Sua senha deve conter ao menos:</p>
        <ul>
            <li>- 8 caracteres <span class="text-danger">✗</span></li> 
            <li>- 1 maiúscula <span class="text-danger">✗</span></li></li>
            <li>- 1 minúscula <span class="text-danger">✗</span></li></li>
            <li>- 1 número <span class="text-danger">✗</span></li></li>
            <li>- 1 caractere especial <span class="text-danger">✗</span></li></li>
            <li id="equal-passwords">- As senhas devem ser iguais <span class="text-danger">✗</span></li></li>
        </ul>
        `);


        return {
            recoveryMode,
            sentRecovery,
            token,
            message,
            passWord,
            confirmPassword,
            equalPasswords,
            loading,
            failOnLoad
        };
    },

    mounted() {
        this.token = this.$route.params.token

        // validate token 
        const validateToken = async () => {

            try {
                const response = await auth.post('/validate_recovery/' + this.token);

                if (response.status === 200) {
                    this.loading = false
                    this.failOnLoad = false 

                } else {
                    this.loading = false
                    this.failOnLoad = true
                }

            } catch (error) {       
                this.loading = false
                this.failOnLoad = true
            }

        }

        validateToken()
    },

    methods: {

        isEqualPasswords() {
            const password1 = document.getElementById("user-ipt")
            const password2 = document.getElementById("pass-ipt")

            this.equalPasswords = password1.value === password2.value
            const listItem = document.getElementById("equal-passwords")

            if (password1.value !== "") {
                listItem.innerHTML = `- As senhas devem ser iguais ${this.equalPasswords ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}`
                return true
            }

            return false
        },

        async updatePasswordValue(submit = false) {

            this.passWord = document.getElementById("user-ipt").value;

            let plus8 = false
            let minus1 = false
            let capital = false
            let number = false
            let special = false
            let valid = false

            if (this.passWord.length >= 8) {
                plus8 = true
            }

            if (/[a-z]/.test(this.passWord)) {
                minus1 = true
            }

            if (/[A-Z]/.test(this.passWord)) {
                capital = true
            }

            if (/[0-9]/.test(this.passWord)) {
                number = true
            }

            if (/[!@#$%^&*]/.test(this.passWord)) {
                special = true
            }

            this.isEqualPasswords()

            if (plus8 && minus1 && capital && number && special && this.equalPasswords) {
                valid = true
            }

            this.message = `
            <p>Sua senha deve conter ao menos:</p>
            <ul>
                <li>- 8 caracteres ${plus8 ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li> 
                <li>- 1 maiúscula ${capital ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
                <li>- 1 minúscula ${minus1 ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
                <li>- 1 número ${number ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
                <li>- 1 caractere especial ${special ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
                <li id="equal-passwords">- As senhas devem ser iguais ${this.equalPasswords ? '<span class="text-success">✓</span>' : '<span class="text-danger">✗</span>'}</li>
            </ul>
            `


            if (submit && valid) {
                const hashedPass = sha256(this.passWord).toString();

                try {
                    this.loading = true
                    const response = await auth.post('/reset_password/' + this.token, { password: hashedPass });

                    if (response.status === 200) {
                        this.loading = false
                        // Go to login page with "recovered" query param
                        this.$router.push({ path: '/login', query: { recovered: true } })
                }

                } catch (error) {
                    this.loading = false
                    this.$emit("notifyError", { message: "<h5 class='fw-bold text-white mb-2'>Erro na solicitação!</h5><p class='text-white mb-0'>Por favor, tente novamente!</p>", id: 2 })
                }

            } else if (submit && !valid) {
                this.$emit("notifyError", { message: 'Sua senha deve atender aos requisitos de segurança', id: 2 })
            }

        }
    },

    watch: {
        recoveryMode: function (newVal) {
            if (newVal === true) {

                this.$nextTick(() => {
                    const emailInput = document.getElementById("recovery-email-ipt");
                    emailInput.focus();
                })

            } else {

                this.$nextTick(() => {
                    const usernameInput = document.getElementById("user-ipt");
                    usernameInput.focus();
                })
            }
        }
    }
};
</script>


<style scoped>

.toggle-access {
    margin-top: 1rem;
}

.logo {
    width: 240px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.logo-igzan {
    width: 80px;
}


input {
    font-size: 1rem !important;
    border-radius: 100px;
    height: 54px;
    border-width: 0.15rem;
    background-color: rgba(255, 255, 255, 0.5);
    /* border-color: #dbdbdb; */
}

input:focus {
    background-color: rgba(255, 255, 255, 0.5);
}

button {
    display: block;
    width: 100%;
    margin-top: 2.3rem;
    border-radius: 100px;
    height: 50px;
    font-size: 1rem !important;
}

.main-div {
    max-width: 500px;
}

.form-div {
    position: relative;
}

.form-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(227, 235, 255, 0.9) 100%);
    backdrop-filter: blur(5px);
    z-index: -1;
    border-radius: 7px;
}


.input-group-text {
    width: 95px;
}

.content {
    height: 200px;
}

.logo-img span {
    font-family: 'ubuntu', sans-serif;
    font-size: 2.4rem;
}
</style>