<template>
    <div class="modal fade" id="lessonModal" tabindex="-1" aria-labelledby="lessonModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="lessonModalLabel">Adicionar aula</h4>
                    <div>

                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="col pt-3">
                        <div class="form-floating mb-3" style="min-width: 200px;">
                            <input type="text" class="form-control need-validation not-null"
                                :class="{ 'is-invalid': invalidInputs.includes('lesson-name-ipt') }"
                                @input="removeInvalid" id="lesson-name-ipt" placeholder="Título"
                                v-model="lessonDataRef.name">
                            <label for="lesson-name-ipt">Título</label>
                            <div class="invalid-feedback ms-2">
                                Campo obrigatório
                            </div>
                        </div>
                        <div class="form-floating mb-3" style="min-width: 200px;">
                            <input type="text" class="form-control need-validation not-null"
                                :class="{ 'is-invalid': invalidInputs.includes('lesson-url-ipt') }"
                                @input="validateVideo" id="lesson-url-ipt" placeholder="URL"
                                v-model="lessonDataRef.url">
                            <label for="lesson-url-ipt">URL Link Dropbox</label>
                            <div class="invalid-feedback ms-2">
                                A URL do vídeo não é válida
                            </div>
                        </div>
                        <div v-if="loadingVideo === true" class="d-flex align-items-center my-3">
                            <strong>Validando link...</strong>
                            <div class="spinner-border text-primary ms-auto" role="status" aria-hidden="true"></div>
                        </div>
                        <div v-if="videoUrl">
                            <video controls="controls" controlslist="nodownload" class="mb-3 w-100 h-100"
                                type="video/mp4" :src="videoUrl" id="lesson-video-element">
                            </video>
                        </div>
                        <div v-if="lessonDataRef.resources && lessonDataRef.resources.filter(resource => resource.type == 'file' && !resource.delete).length > 0"
                            id="resources-list " class="mb-3">
                            <h6>Recursos de aula</h6>
                            <div v-for="resource in lessonDataRef.resources.filter(resource => resource.type == 'file' && !resource.delete)"
                                :key="resource" class="card m-0 mb-1 rounded resource">
                                <div class="card-body p-1 px-3 m-0 hstack">
                                    <p class="w-100 d-inline m-0 my-1">{{ resource.name }}</p>
                                    <div class="ms-auto">
                                        <i class="bi bi-x-lg pointer delete-resource"
                                            @click="deleteResource(resource)"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="addingResource" id="send-resource">
                            <form @submit.prevent="sendResource" @keydown.enter.prevent="sendResource">
                                <div class="mb-3">
                                    <input class="form-control mt-3" type="file" id="formFile"
                                        :class="{ 'is-invalid': invalidInputs.includes('formFile') }">
                                    <div class="invalid-feedback ms-2">
                                        Nenhum arquivo selecionado
                                    </div>
                                    <div class="d-flex justify-content-between gap-2">
                                        <button type="button" class="btn btn-outline-secondary my-3 w-100"
                                            @click="cancelSendResource"><i class="bi bi-x-lg me-2"></i>Cancelar</button>
                                        <button type="button" class="btn btn-outline-primary my-3 w-100"
                                            @click="sendResource"><i class="bi bi-plus-lg me-2"></i>Adicionar recurso de
                                            aula</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-else>
                            <button type="button" class="btn btn-outline-primary mb-3 w-100"
                                @click="this.addingResource = true"><i class="bi bi-plus-lg me-2"></i>Adicionar recurso
                                de aula</button>
                        </div>

                        <div v-if="lessonDataRef.resources && lessonDataRef.resources.filter(resource => resource.type == 'link' && !resource.delete).length > 0"
                            id="resources-list " class="mb-3">
                            <h6>Links externos referenciados</h6>
                            <div v-for="resource in lessonDataRef.resources.filter(resource => resource.type == 'link' && !resource.delete)"
                                :key="resource" class="card m-0 mb-1 rounded resource">
                                <div class="card-body p-1 px-3 m-0 hstack">
                                    <div class="vstack">
                                        <p class="w-100 d-inline m-0">{{ resource.name }}</p>
                                        <p class="w-100 d-inline m-0 text-secondary">{{ resource.url }}</p>
                                    </div>
                                    <div class="ms-auto">
                                        <i class="bi bi-x-lg pointer delete-resource"
                                            @click="deleteResource(resource)"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="this.addingLink" id="send-resource">
                            <form @submit.prevent="sendLink" @keydown.enter.prevent="sendLink">
                                <div class="form-floating mb-3" style="min-width: 200px;">
                                    <input type="text" class="form-control need-validation not-null" id="link-name-ipt"
                                        placeholder="Nome"
                                        :class="{ 'is-invalid': invalidInputs.includes('link-name-ipt') }">
                                    <label for="link-name-ipt">Nome</label>
                                    <div class="invalid-feedback ms-2">
                                        Campo obrigatório
                                    </div>
                                </div>

                                <div class="form-floating" style="min-width: 200px;">
                                    <input type="url" class="form-control need-validation not-null" id="link-url-ipt"
                                        placeholder="URL"
                                        :class="{ 'is-invalid': invalidInputs.includes('link-url-ipt') }">
                                    <label for="link-url-ipt">URL Link</label>
                                    <div class="invalid-feedback ms-2">
                                        A URL do link não é válida
                                    </div>

                                </div>
                                <div class="d-flex justify-content-between gap-2">
                                    <button type="button" class="btn btn-outline-secondary my-3 w-100"
                                        @click="cancelSendLink"><i class="bi bi-x-lg me-2"></i>Cancelar</button>
                                    <button type="button" class="btn btn-outline-primary my-3 w-100"
                                        @click="sendLink"><i class="bi bi-plus-lg me-2"></i>Adicionar link de
                                        aula</button>
                                </div>

                            </form>
                        </div>
                        <div v-else>
                            <button type="button" class="btn btn-outline-primary mb-2 w-100"
                                @click="this.addingLink = true"><i class="bi bi-plus-lg me-2"></i>Referenciar link
                                externo
                                de aula</button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <div>
                        <button v-if="lessonDataRef.id || lessonDataRef.new" type="button"
                            class="btn btn-outline-danger" @click="deleteLesson">
                            <i class="bi bi-trash fs-4 me-2"></i>Excluir aula
                        </button>
                    </div>
                    <div class="d-flex gap-2">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary" :disabled="loadingVideo === true"
                            @click="sendLesson">Salvar aula</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { ref, toRef, onMounted } from 'vue';
import { Modal } from 'bootstrap';
// import http from '@/services/http';


export default {
    name: "lessonModal",
    props: ['lessonData', 'module'],
    emits: ['playSound', 'sendLesson'],

    setup(props) {
        const invalidInputs = ref([]);
        const videoUrl = ref(null);
        const videoDuration = ref(0);
        const loadingVideo = ref(false);
        const addingResource = ref(false);
        const addingLink = ref(false);

        const lessonDataRef = toRef(props, 'lessonData');

        const clearModal = () => {
            addingResource.value = false;
            addingLink.value = false;
            invalidInputs.value = [];
            videoUrl.value = null;
            videoDuration.value = 0;
        }

        onMounted(() => {
            const modal = document.getElementById('lessonModal');

            modal.addEventListener('hidden.bs.modal', () => {
                clearModal();
            })

        })

        return {
            invalidInputs,
            videoUrl,
            videoDuration,
            loadingVideo,
            addingResource,
            addingLink,
            lessonDataRef,
            clearModal,
        }
    },

    watch: {
        'lessonData': {
            handler: function () {
                // console.log(newValue);
                // this.lessonDataRef = newValue;
                // if (this.lessonData.url !== '') {
                //     this.validateVideo({ target: { value: this.lessonDataRef.url } });
                // }

            },
            deep: true
        },

        'lessonData.url': function () {
            if (this.lessonData.url !== '') {
                this.validateVideo({ target: { value: this.lessonDataRef.url } });
            }
        }
    },

    methods: {
        removeInvalid(event) {
            const targetId = event.target.id;
            if (this.invalidInputs.includes(targetId)) {
                this.invalidInputs.splice(this.invalidInputs.indexOf(targetId), 1);
            }
        },


        async validateVideo(event) {
            let link = event.target.value.trim();
            // alert(link);

            if (link !== "") {
                this.loadingVideo = true;

                if (this.invalidInputs.includes('lesson-url-ipt')) {
                    this.invalidInputs.splice(this.invalidInputs.indexOf('lesson-url-ipt'), 1);
                }

                if (link.includes("dropbox.com") && link.includes("dl=0")) {
                    link = link.replace("dl=0", "raw=1");
                }

                // try {
                //     const response = await http.get(`/get-dropbox-resource?url=${encodeURIComponent(link)}`);
                //     if (response.status === 200) {
                //         // getting body "url" from response
                //         link = response.data;
                //     }
                // } catch (error) {
                //     console.log(error);
                // }

                this.videoUrl = link;

                this.$nextTick(() => {
                    let video = document.getElementById('lesson-video-element');

                    video.addEventListener('loadstart', () => {
                        this.loadingVideo = true;
                    });

                    video.addEventListener('loadedmetadata', () => {
                        this.loadingVideo = false;
                        this.videoDuration = video.duration;
                    });

                    video.addEventListener('error', () => {
                        this.loadingVideo = false;
                        this.videoUrl = null;
                        this.videoDuration = 0;
                        if (!this.invalidInputs.includes('lesson-url-ipt')) {
                            this.invalidInputs.push('lesson-url-ipt');
                        }
                    });

                });

            } else {
                this.videoUrl = null;
                this.videoDuration = 0;
                if (!this.invalidInputs.includes('lesson-url-ipt')) {
                    this.invalidInputs.push('lesson-url-ipt');
                }
            }
        },

        sendResource() {
            const fileInput = document.getElementById('formFile');

            if (fileInput.files.length === 0) {
                if (!this.invalidInputs.includes('formFile')) {
                    this.invalidInputs.push('formFile');
                }
                this.$emit('playSound', 'mistake');
                return;
            }

            if (this.invalidInputs.includes('formFile')) {
                this.invalidInputs.splice(this.invalidInputs.indexOf('formFile'), 1);
            }


            this.lessonDataRef.resources.push({
                name: fileInput.files[0].name,
                url: fileInput.files[0].name,
                type: 'file',
                src: fileInput.files[0],
                timestamp: Date.now(),
            })

            // console.log(this.lessonDataRef.resources)

            fileInput.value = null;
            this.addingResource = false;

            console.log(this.lessonDataRef.resources)


        },

        cancelSendResource() {
            this.addingResource = false;
            const fileInput = document.getElementById('formFile');
            fileInput.value = null;
            if (this.invalidInputs.includes('formFile')) {
                this.invalidInputs.splice(this.invalidInputs.indexOf('formFile'), 1);
            }
        },

        sendLink() {
            const linkInput = document.getElementById('link-name-ipt');
            const linkUrlInput = document.getElementById('link-url-ipt');
            let invalid = false;

            if (!linkUrlInput.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g)) {
                if (!this.invalidInputs.includes('link-url-ipt')) {
                    this.invalidInputs.push('link-url-ipt');
                }
                invalid = true;

            } else {
                if (this.invalidInputs.includes('link-url-ipt')) {
                    this.invalidInputs.splice(this.invalidInputs.indexOf('link-url-ipt'), 1);
                }
            }

            if (linkInput.value.trim() === "") {
                if (!this.invalidInputs.includes('link-name-ipt')) {
                    this.invalidInputs.push('link-name-ipt');
                }
                invalid = true;

            } else {
                if (this.invalidInputs.includes('link-name-ipt')) {
                    this.invalidInputs.splice(this.invalidInputs.indexOf('link-name-ipt'), 1);
                }
            }


            if (invalid) {
                this.$emit("playSound", "mistake");
                return;
            }

            if (this.invalidInputs.includes('link-name-ipt')) {
                this.invalidInputs.splice(this.invalidInputs.indexOf('link-name-ipt'), 1);
            }

            if (this.invalidInputs.includes('link-url-ipt')) {
                this.invalidInputs.splice(this.invalidInputs.indexOf('link-url-ipt'), 1);
            }

            this.lessonDataRef.resources.push({
                name: linkInput.value,
                url: linkUrlInput.value,
                type: 'link',
            })

            // console.log(this.lessonDataRef.resources)

            linkInput.value = '';
            linkUrlInput.value = '';
            this.addingLink = false;

            console.log(this.lessonDataRef.resources)

        },

        cancelSendLink() {
            this.addingLink = false;
            const linkInput = document.getElementById('link-name-ipt');
            const linkUrlInput = document.getElementById('link-url-ipt');
            linkInput.value = '';
            linkUrlInput.value = '';

            if (this.invalidInputs.includes('link-name-ipt')) {
                this.invalidInputs.splice(this.invalidInputs.indexOf('link-name-ipt'), 1);
            }

            if (this.invalidInputs.includes('link-url-ipt')) {
                this.invalidInputs.splice(this.invalidInputs.indexOf('link-url-ipt'), 1);
            }

            this.addingLink = false;
        },

        sendLesson() {
            const modal = Modal.getInstance(document.getElementById('lessonModal'));
            const lessonNameInput = document.getElementById('lesson-name-ipt');
            const lessonUrlInput = document.getElementById('lesson-url-ipt');
            let invalid = false;

            if (lessonNameInput.value.trim() === "") {
                if (!this.invalidInputs.includes('lesson-name-ipt')) {
                    this.invalidInputs.push('lesson-name-ipt');
                }
                invalid = true;
            }

            if (lessonUrlInput.value.trim() === "") {
                if (!this.invalidInputs.includes('lesson-url-ipt')) {
                    this.invalidInputs.push('lesson-url-ipt');
                }
                invalid = true;
            }

            if (invalid || this.invalidInputs.length > 0) {
                this.$emit('playSound', 'mistake');
                return;
            }

            this.lessonDataRef.name = lessonNameInput.value;
            this.lessonDataRef.url = lessonUrlInput.value;
            this.lessonDataRef.duration = this.videoDuration;


            this.$emit('sendLesson', this.lessonDataRef);
            lessonNameInput.value = '';
            lessonUrlInput.value = '';
            modal.hide();

        },

        deleteLesson() {
            const modal = Modal.getInstance(document.getElementById('lessonModal'));
            this.$emit('deleteLesson', this.lessonDataRef);
            modal.hide();
            this.clearModal();
        },

        deleteResource(resource) {
            if (resource.id) {
                const currentResource = this.lessonDataRef.resources.find(res => res.id === resource.id);
                if (currentResource) {
                    currentResource.delete = true
                }

            } else {
                this.lessonDataRef.resources.splice(this.lessonDataRef.resources.indexOf(resource), 1)
            }
            console.log(this.lessonDataRef.resources)
        }

    }
}
</script>


<style scoped>
.delete-resource:hover {
    color: var(--bs-danger);
}

.resource:hover {
    background-color: var(--bs-light);
}

.delete-lesson-icon:hover {
    color: var(--bs-danger);
}
</style>