<template>
  <div class="modal" id="bugReportModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
    <div class="modal-dialog px-sm-4" style="max-width: 500px; min-width: 300px;">
      <div class="modal-content h-100 p-4 pb-2">
        <div class="modal-header p-0 mb-2">
          <h2 class="modal-title pb-1" id="confirmModalLabel">
            <i class="me-1 ms-0 bi bi-bug text-primary"></i>
            Bug Report
          </h2>
          <button type="button" class="btn-close" @click="cancelAction" id="closeModal"></button>
        </div>
        <div class="modal-body p-0">
          <div v-if="!bugRecorded">
            <div v-if="failReport" class="alert alert-danger" role="alert">
              Houve um problema no registro do seu relatório. Por favor, tente novamente mais tarde.
            </div>
            <p>Estamos constantemente melhorando o sistema da Kahun. Nesse processo alguns bugs podem ocorrer, e você
              pode
              colaborar a ter uma resolução mais veloz, basta descrever abaixo o problema que você encontrou.</p>
            <p>Para
              otimizar o processo de resolução, tente ser o mais descritível possível, dando todos os detalhes que
              achar
              relevante:</p>
            <div class="form-floating mb-3">
              <textarea class="form-control mb-2 mx-0" placeholder="Leave a comment here" id="floatingTextAreaBug" :disabled="loading"
                style="height: 100px" @input="this.invalidInputs = []"
                :class="{ 'is-invalid': invalidInputs.includes('floatingTextAreaBug') }"></textarea>
              <label for="floatingTextarea2">Descrição</label>
              <div class="invalid-feedback ms-2">
                Campo obrigatório
              </div>
            </div>
          </div>
          <div v-else>
            <h5 class="mt-3">Seu relatório foi enviado com sucesso!</h5>
            <div class="alert alert-success mt-3" role="alert">
              <p>
                Obrigado por sua colaboração com a melhoria do sistema da Kahun. Seu relatório foi registrado com
                sucesso.
              </p>
              <p>
                Caso haja necessidade de informações complementares, um membro de nossa equipe entrará em contato com
                você!
              </p>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-end m-0 p-0 py-2">
            <button type="button" class="btn btn-outline-secondary col col-sm-4 m-0" :disabled="loading"
              @click="bugRecorded ? cancelAction() : sendBugReport()">
              <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{ bugRecorded ? 'Ok!' : loading ? 'Enviando...' : 'Enviar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import http from "@/services/http";
import { ref } from 'vue';

export default {
  name: 'bugReportModal',
  setup() {
    const invalidInputs = ref([]);
    const bugRecorded = ref(false);
    const failReport = ref(false);
    const loading = ref(false);

    return {
      invalidInputs,
      bugRecorded,
      failReport,
      loading
    }
  },

  methods: {

    cancelAction() {
      // close popup
      this.invalidInputs = []
      this.bugRecorded = false
      this.failReport = false
      const modal = Modal.getInstance(document.getElementById('bugReportModal'))
      modal.hide()
    },

    async sendBugReport() {

      let data
      const content = document.getElementById('floatingTextAreaBug')
      
      if (content.value.trim() === '') {
        this.invalidInputs.push('floatingTextAreaBug')
        this.$emit('playSound', 'mistake')
        return
      }
      
      data = {
        description: content.value,
        url_location: window.location.href
      }
      
      try {
        this.loading = true 
        const response = await http.post('/bug_report', data)

        if (response.status === 200) {
          this.invalidInputs = []
          this.bugRecorded = true
          content.value = ''
          this.$emit('playSound', 'notification')
        }

      } catch (error) {
        this.$emit('playSound', 'mistake')
        console.log(error)
        this.failReport = true
      }

      this.loading = false
    }
  }
}

</script>

<style scoped>
.modal-dialog {
  max-width: 450px;
}

textarea {
  height: 100px;
}

.invalid textarea {
  border: 2px solid var(--bs-danger);
  transition: border-color 0.3s ease;
  animation: shine 0.5s ease-out;
}

.invalid p {
  display: block !important;
  transition: border-color 0.3s ease;
  animation: shine 0.5s ease-out;
}
</style>