<template>
    <div class="modal" :id="id" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
      <div class="modal-dialog px-sm-4">
        <div class="modal-content h-100 p-4 pb-2">
          <div class="modal-header p-0 mb-2">
            <h2 class="modal-title pb-1" id="confirmModalLabel">
              <i class="me-1 ms-0 bi bi-info-circle-fill text-primary"></i>
              {{ title }}
            </h2>
            <button type="button" class="btn-close" @click="cancelAction" id="closeModal"></button>
          </div>
          <div class="modal-body p-0">
            <p v-html="content"></p>
            <div class="modal-footer d-flex flex-row justify-content-end m-0 p-0 py-2">
  
              <button type="button" class="btn btn-outline-secondary col col-sm-4"
                @click="cancelAction">Ok!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Modal } from 'bootstrap'
  
  export default {
    name: 'infoModal',
    props: ['title', 'content', 'id'],
    methods: {
  
      cancelAction () {
        // close popup
        const modal = Modal.getInstance(document.getElementById(this.id))
        modal.hide()
      }
    }
  }
  
  </script>
  
  <style scoped>
  .modal-dialog {
    max-width: 450px;
  }
  
  textarea {
    height: 100px;
    resize: none;
  }
  
  .invalid textarea {
    border: 2px solid var(--bs-danger);
    transition: border-color 0.3s ease;
    animation: shine 0.5s ease-out;
  }
  
  .invalid p {
    display: block !important;
    transition: border-color 0.3s ease;
    animation: shine 0.5s ease-out;
  }
  </style>