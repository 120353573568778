<template>
    <div class="modal fade" id="inviteUserModal" tabindex="-1" aria-labelledby="accessRestrictionModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header p-4">
                    <h4 class="modal-title" id="accessRestrictionModalLabel">Adicionar usuário</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-wrapper px-2">
                    <div class="modal-body p-3 pt-0">
                        <div class="form-floating mb-3">
                            <input type="email" class="form-control" id="floatingInputEmail"
                                placeholder="Email de usuário" @input="removeInvalid($event)" :disabled="loading"
                                :class="{ 'is-invalid': invalidInputs.includes('floatingInputEmail') }">
                            <label for="floatingInputEmail">Email</label>
                            <div class="invalid-feedback ms-2">
                                É obrigatório inserir um email válido
                            </div>
                        </div>
                        <div class="form-floating mb-0">
                            <select v-if="multiAccess" class="form-select" id="floatingSelectBusinessUnit"
                                :disabled="loading" aria-label="Floating label select example"
                                @change="changeUnit($event)" v-model="selectedUnit">
                                <option v-for="unit in accessUnits" :key="unit.id" :value="unit.id"
                                    :selected="unit.id == currentUnit">{{ unit.nickname }}{{ unit.headquarters ? ' - Central ★' : '' }}
                                </option>
                            </select>
                            <label for="floatingSelectBusinessUnit">Selecione uma unidade</label>
                        </div>
                    </div>
                    <div class="modal-body px-4 py-0">
                        <div v-if="userAdmin">
                            <h5 class="mt-2">Propriedade da unidade</h5>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="unitOwnerCheck"
                                    :checked="unitOwnerRef" :disabled="loading"
                                    @change="unitOwnerRef === true ? unitOwnerRef = false : unitOwnerRef = true">
                                <label class="form-check-label" for="unitOwnerCheck">
                                    O usuário é proprietário desta unidade
                                </label>
                            </div>
                        </div>
                        <div v-for="privilege in privilegeList" :key="privilege">
                            <h5 class="mt-3">{{ privilege.category }}</h5>
                            <div v-for="restriction in privilege.privileges" :key="restriction.id">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" :disabled="loading"
                                        :id="'flexCheckDefaultRole' + restriction.id"
                                        :checked="selectedPrivilegesIds.includes(restriction.id)"
                                        @change="selectedPrivilegesIds.includes(restriction.id) ? removePrivilege(restriction) : addPrivilege(restriction)">
                                    <label class="form-check-label" :for="'flexCheckDefaultRole' + restriction.id">
                                        {{ restriction.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer px-3">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary" @click="inviteUser" :disabled="loading">
                        <span class="spinner-border spinner-border-sm me-2 text-white" role="status" aria-hidden="true"
                            v-if="loading"></span>Enviar</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import http from "@/services/http";
import { onMounted, ref } from "vue";
import { Modal } from 'bootstrap';

export default {
    name: "inviteUserModal",
    emits: ['updatePrivileges', 'notifyError', 'notifySuccess', 'playSound'],
    props: ['selectedPrivileges', 'unitOwner', 'accessUnits', 'currentUnit'],


    setup() {
        const privilegeList = ref([]);
        // console.log(props.selectedPrivileges);
        const selectedPrivilegesRef = ref([]);
        const selectedPrivilegesIds = ref([]);
        const unitOwnerRef = ref(false);
        const userAdmin = localStorage.getItem('user_admin');
        const invalidInputs = ref([]);
        const multiAccess = localStorage.getItem('multi_access');
        const headquarters = ref(JSON.parse(localStorage.getItem('user_roles')).headquarters);
        const loading = ref(false);
        let selectedUnit;


        const getUnit = async (headquarters = false) => {
            try {
                const endpoint = headquarters ? 'privileges/true' : 'privileges';
                http.get(endpoint)
                    .then((response) => {
                        privilegeList.value = response.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error);
            }
        }

        onMounted(() => {
            const inviteModal = document.getElementById('inviteUserModal');
            getUnit(headquarters.value);

            inviteModal.addEventListener('hidden.bs.modal', () => {
                invalidInputs.value = [];
                selectedPrivilegesRef.value = [];
                selectedPrivilegesIds.value = [];
                unitOwnerRef.value = false;
                console.log(selectedPrivilegesRef.value);
            })

        });

        return {
            privilegeList,
            selectedPrivilegesRef,
            selectedPrivilegesIds,
            unitOwnerRef,
            userAdmin,
            invalidInputs,
            multiAccess,
            selectedUnit,
            headquarters,
            loading
        }
    },

    watch: {
        selectedPrivileges() {
            this.selectedPrivilegesRef = this.selectedPrivileges;
            this.selectedPrivilegesIds = this.selectedPrivilegesRef.map(p => p.id);
        },

        currentUnit(newValue) {
            if (newValue) {
                this.changeUnit({ target: { value: this.currentUnit } });
            }
        },
    },

    methods: {
        removeInvalid(event) {
            const targetId = event.target.id;
            if (this.invalidInputs.includes(targetId)) {
                this.invalidInputs.splice(this.invalidInputs.indexOf(targetId), 1);
            }
        },

        addPrivilege(privilege) {
            this.selectedPrivilegesRef.push(privilege);
            this.selectedPrivilegesIds.push(privilege.id);
            console.log(this.selectedPrivilegesRef);
        },
        removePrivilege(privilege) {
            this.selectedPrivilegesRef = this.selectedPrivilegesRef.filter(p => p.id !== privilege.id);
            this.selectedPrivilegesIds.splice(this.selectedPrivilegesIds.indexOf(privilege.id), 1);
            console.log(this.selectedPrivilegesRef);
        },
        updatePrivileges() {
            this.$emit('updatePrivileges', this.selectedPrivilegesRef);
        },

        async changeUnit(event) {
            try {
                const unitId = event.target.value;
                let link = 'privileges'
                this.selectedUnit = unitId

                const unitInfo = this.accessUnits.find(unit => unit.id === unitId);

                if (unitInfo.headquarters === true) {
                    link = 'privileges/true'
                }

                http.get(link)
                    .then((response) => {
                        this.selectedPrivilegesRef = [];
                        this.selectedPrivilegesIds = [];
                        this.unitOwnerRef = false;
                        this.privilegeList = response.data;

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        async inviteUser() {
            const inviteModal = Modal.getInstance(document.getElementById('inviteUserModal'));
            const unitId = this.selectedUnit || this.currentUnit;
            const unitOwner = document.getElementById('unitOwnerCheck').checked;
            const privileges = this.selectedPrivilegesIds;
            const email = document.getElementById('floatingInputEmail');

            // check is is an valid email
            const re = /\S+@\S+\.\S+/;

            if (!re.test(email.value)) {
                this.invalidInputs.push('floatingInputEmail');
                this.$emit('playSound', 'mistake');
                return;
            }

            const data = {
                business_unit: unitId,
                is_owner: unitOwner,
                roles: privileges,
                email: email.value
            }

            try {
                this.loading = true;
                const response = await http.post('/send_invite', data);

                if (response.status === 200) {
                    if (response.data === 'new_user') {
                        this.$emit('playSound', 'confirm');
                        this.$emit('notifySuccess', { message: "<h5 class='fw-bold text-white mb-2'>Usuário convidado com sucesso!</h5><p class='text-white mb-0'>Um email foi enviado para o covidado para que ele possa se cadastrar.</p>", id: 1 });

                    } else if (response.data === 'existing_user') {
                        this.$emit('playSound', 'confirm');
                        this.$emit('notifySuccess', { message: "<h5 class='fw-bold text-white mb-2'>Usuário já existe!</h5><p class='text-white mb-0'>O usuário já estava cadastrado no sistema, porém agora ele terá acesso a essa unidade. Um e-mail foi enviado a ele para informa-lo.</p>", id: 2 });
                    }

                    email.value = '';
                    this.selectedPrivilegesRef = [];
                    this.selectedPrivilegesIds = [];
                    this.unitOwnerRef = false;
                    this.invalidInputs = [];
                    inviteModal.hide();
                }

            } catch (error) {

                if (error.response && error.response.status === 400 && error.response.data === 'user_already_in_business_unit') {
                    this.$emit('playSound', 'somethingWeird');
                    this.$emit('notifyError', { message: "<h5 class='fw-bold text-white mb-2'>Erro ao convidar o usuário!</h5><p class='text-white mb-0'>Esse usuário já pertence a essa unidade.</p>", id: 3 });

                } else if (error.response && error.response.status === 400 && error.response.data === 'user_blocked') {
                    this.$emit('playSound', 'somethingWeird');
                    this.$emit('notifyError', { message: "<h5 class='fw-bold text-white mb-2'>Erro ao convidar o usuário!</h5><p class='text-white mb-0'>O acesso desse usuário foi revogado pelo administrador do sistema.</p>", id: 4 });

                } else {
                    console.log(error);
                    console.log(data)
                    this.$emit('playSound', 'mistake');
                    this.$emit('notifyError', { message: "<h5 class='fw-bold text-white mb-2'>Erro ao convidar o usuário!</h5><p class='text-white mb-0'>Tente novamente mais tarde.</p>", id: 5 });
                }
            }

            this.loading = false;

        }
    }
}
</script>


<style scoped></style>