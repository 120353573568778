<template>
    <div class="modal fade" id="accessRestrictionModal" tabindex="-1" aria-labelledby="accessRestrictionModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="accessRestrictionModalLabel">Restrição de acesso</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4 pt-0">
                    <div v-for="privilege in privilegeList" :key="privilege" >
                        <h5 class="mt-3">{{privilege.category}}</h5>
                        <div v-for="restriction in privilege.privileges" :key="restriction.id">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" :id="'flexCheckDefault' + restriction.id" 
                                :checked="selectedPrivilegesIds.includes(restriction.id)"
                                @change="selectedPrivilegesIds.includes(restriction.id) ? removePrivilege(restriction) : addPrivilege(restriction)">
                                <label class="form-check-label" :for="'flexCheckDefault' + restriction.id">
                                    {{restriction.name}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="px-2 mb-3">
                        <h6 class="m-0 text-primary"> <i class="bi bi-info-circle"></i>  O usuário precisará ter todos os privilégios selecionados para acessar esse curso.</h6>
                    </div>

                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary" @click="updatePrivileges">Enviar</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import http from "@/services/http";
import { ref } from "vue";

export default {
    name: "accessRestrictionModal",
    emits: ['updatePrivileges'],
    props: ['selectedPrivileges'],


    setup(props) {
        const privilegeList = ref([]);
        // console.log(props.selectedPrivileges);
        const selectedPrivilegesRef = props.selectedPrivileges ? ref(props.selectedPrivileges) : ref([]);
        const selectedPrivilegesIds = selectedPrivilegesRef.value.map(p => p.id);

        try {
            http.get("privileges/true")
                .then((response) => {
                    privilegeList.value = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }

        return {
            privilegeList,
            selectedPrivilegesRef,
            selectedPrivilegesIds
        }
    },
    
    watch: {
        selectedPrivileges() {
            this.selectedPrivilegesRef = this.selectedPrivileges;
            this.selectedPrivilegesIds = this.selectedPrivilegesRef.map(p => p.id);
        }
    },

    methods: {
        addPrivilege(privilege) {
            this.selectedPrivilegesRef.push(privilege);
            this.selectedPrivilegesIds.push(privilege.id);
            console.log(this.selectedPrivilegesRef);
        },
        removePrivilege(privilege) {
            this.selectedPrivilegesRef = this.selectedPrivilegesRef.filter(p => p.id !== privilege.id);
            this.selectedPrivilegesIds.splice(this.selectedPrivilegesIds.indexOf(privilege.id), 1);
            console.log(this.selectedPrivilegesRef);
        },
        updatePrivileges() {
            this.$emit('updatePrivileges', this.selectedPrivilegesRef);
        }
    }
}
</script>


<style scoped>

</style>