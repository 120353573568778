<template>
    <div class="container p-5 d-flex align-items-center justify-content-center" id="wrapper">
        <div class="container d-flex align-items-center justify-content-center flex-column"
            style="max-width: 650px">
            <HeaderLogo />
            <h1 class="text-center mt-4">404 Page Not Found</h1>
            <h5 class="text-center my-4">Ops! Parece que essa pagina que esta procurando não existe ou foi removida.
            </h5>
            <img src="../../assets/images/images/404 Error with a cute animal-rafiki.svg">
            <button class="btn btn-lg btn-primary" @click="$router.push('/')">Voltar para o inicio</button>
        </div>
    </div>
</template>

<script>
import HeaderLogo from './HeaderLogo.vue'

export default {
    name: "pageNotFound404",
    components: {
        HeaderLogo
    }
}
</script>

<style scoped>
img {
    max-height: 50vh;
    width: auto;
}

#wrapper {
    background-color: var(--bs-body-bg);   
    min-height: 100vh;
    min-width: 100vw;
}
</style>