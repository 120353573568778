<template>
    <div class="wrapper p-3 justify-content-between" :class="{ 'wrapper-open': moduleOpened }"
        @click="moduleOpened = !moduleOpened">
        <div class="d-flex flex-nowrap align-self-stretch">
            <div class="w-100">
                <h6 class="content mb-1"
                    :class="{ 'fw-bolder': moduleOpened, 'text-primary fw-bolder': (lessonData.module === moduleData.id) }">
                    {{
                        moduleData.name }}</h6>
                <div class="hstack mt-2">
                    <p class="small-subtitle text-secondary m-0">{{ moduleData.lessons.filter(lesson =>
                        lesson.completed).length }}/{{ moduleData.lessons.length }} </p>
                    <p class="small-subtitle text-secondary mx-2 my-0">|</p>
                    <p class="small-subtitle text-secondary m-0">{{ formatTime(moduleData.total_time_seconds) }}</p>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <i class="bi bi-chevron-down ms-3" :class="{ 'collapsed': !moduleOpened }"></i>
            </div>
        </div>
    </div>

    <div v-if="moduleOpened">
        <div v-for="lesson in moduleData.lessons" v-bind:key="lesson.id" :id="this.$route.params.lesson == lesson.id ? `current-lesson-${index}` : ''">
            <a :href="`/learning/${courseSlug}/lecture/${lesson.id}`">
                <div class="hstack p-2 align-items-start lesson-card">
                    <input class="form-check-input me-2 mt-1 lesson-checkbox" type="checkbox" id="checkboxNoLabel"
                        :checked="lesson.completed" @change="updateCompleted(lesson.id, !lesson.completed)">
                    <div class="w-100 vstack p-0">
                        <p class="mb-1" :class="{ 'text-primary': courseLesson == lesson.id }">{{ lesson.name }}</p>

                        <div class="d-flex justify-content-between">

                            <div class="hstack p-0 align-items-start small-subtitle w-100">
                                <i class="bi me-2 text-secondary"
                                    :class="{ 'bi-play-btn': lesson.type == 'Video', 'bi-question-circle': lesson.type == 'Quiz', 'bi-file-earmark-text': lesson.type == 'Article' }"></i>
                                <p class="text-secondary small-subtitle">{{ formatTime(lesson.duration) }}</p>
                            </div>


                            <div v-if="lesson.resources[0]" class="navbar-collapse justify-content-end px-0" id="navbarNav"
                                @click.prevent>
                                <ul class="navbar-nav align-items-end justify-content-center">
                                    <li class="nav-item drop">
                                        <div class="btn btn-sm btn-outline-secondary">
                                            <i class="bi bi-folder-fill fs-3 mx-2"></i>
                                            Recursos
                                            <i class="bi bi-chevron-down mx-2"></i>
                                        </div>
                                        <div class="drop-content">
                                            <div class="resources">
                                                <div v-for="lesonResource in lesson.resources"
                                                    v-bind:key="lesonResource.id">
                                                    <div v-if="lesonResource.type == 'file'"
                                                        class="resource hstack gap-2 p-2 w-100"
                                                        @click="downloadResource(lesonResource)">
                                                        <i class="ti ti-download fs-6"></i>
                                                        <p class="mb-0 fs-3">{{ lesonResource.name }}</p>
                                                    </div>
                                                    <a v-else class="resource hstack gap-2 p-2 w-100"
                                                        rel="noopener noreferrer" target="_blank" :href="lesonResource.url"
                                                        @click.stop="">
                                                        <i class="bi bi-box-arrow-up-right fs-6"></i>
                                                        <p class="mb-0 fs-3">{{ lesonResource.name }}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </a>
        </div>
    </div>
</template>


<script>
import { ref } from 'vue';
import { onMounted } from 'vue';
import http from "@/services/http"

export default {

    name: "CourseModuleCard",
    props: ['moduleData', 'bookmark', 'lessonData', 'index'],
    emits: ['update-completed', 'notifyError'],

    data() {
        return {
            courseLesson: null,
            courseSlug: null,
        }
    },
    setup(props, ctx) {
        const moduleOpened = ref(false);
        const lessonRefs = ref(null);

        const downloadResource = async (resource) => {
            try {
                const response = await http.get(`/lesson_resources/${resource.id}`, {
                    responseType: 'blob'
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.download = resource.url;
                link.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                ctx.emit('notifyError', {message: 'Erro ao fazer o download do recurso, ou ele está indisponível.', id: 2});
                // alert('Erro ao fazer o download do recurso, ou ele está indisponível.', error);
            }
        };


        function formatTime(totalSeconds) {
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);

            const formattedHours = String(hours).padStart(1, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            if (hours == 0) {
                return `${formattedMinutes}m`;
            } else if (hours > 24) {
                const days = Math.floor(hours / 24);
                return `${days}d ${formattedHours}h ${formattedMinutes}m`;
            } else {
                return `${formattedHours}h ${formattedMinutes}m`;
            }
        }

        // onUpdated(lessonRefs.value = null)


        onMounted(() => {
            if (props.lessonData.module == props.moduleData.id) {
                moduleOpened.value = true
            }

            lessonRefs.value = props.moduleData.lessons
        })

        return {
            formatTime,
            moduleOpened,
            downloadResource,
            lessonRefs,
        }
    },


    mounted() {
        const courseLesson = this.$route.params.lesson;
        const courseSlug = this.$route.params.course
        this.courseSlug = courseSlug;
        this.courseLesson = courseLesson;
    },

    methods: {

        updateCompleted(lessonId, completed) {
            // alert(lessonId, completed)
            if (this.courseSlug) {
                this.moduleData.lessons.find(lesson => lesson.id == lessonId).completed = completed
                this.$emit('update-completed', { lessonId, completed });
            }
        },
    }
}


</script>

<style scoped>
.wrapper,
.lesson-card {
    width: 100%;
    min-height: 70px;
    cursor: pointer;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    user-select: none;
}

.bi-chevron-down {
    transition: transform 0.2s ease-in-out;
}

.bi-chevron-down:not(.collapsed) {
    transform: rotate(180deg);
}

.small-subtitle {
    font-size: 0.7rem !important;
}

.wrapper-open {
    background-color: rgba(var(--bs-secondary-rgb), 0.12);
}

.wrapper:hover {
    background-color: rgba(var(--bs-secondary-rgb), 0.1);
}

.content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 50px;
}

.form-check-input {
    min-width: 20px;
}

.lesson-card {
    min-height: 50px;
}

.lesson-card:hover {
    background-color: rgba(var(--bs-secondary-rgb), 0.05);
}

.lesson-card p:first-of-type {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 75px;
}

.form-check-input {
    cursor: pointer;
}

.drop {
    position: relative;
    display: inline-block;
    /* z-index: 0 !important; */

}

.drop-content {
    display: none;
    position: absolute !important;
    width: 260px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* z-index: 0 !important; */
    right: 0px;
    top: 31px;
    background-color: var(--bs-body-bg);
    border-radius: 5px;
    max-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
}

.drop:hover .drop-content {
    display: block;
}

.resources {
    position: fixed;
    right: 0.5rem;
    z-index: 9 !important;
    background-color: var(--bs-body-bg);
    border-radius: 5px;
    width: 260px;
    box-shadow: 0px 8px 16px 0px rgba(var(--bs-secondary-rgb), 0.3);
}

.resource {
    height: 45px;
    min-width: 100px;
    max-width: 350px;
    width: fit-content;
    border-radius: 5px;
}

.resource:hover {
    background-color: rgba(var(--bs-secondary-rgb), 0.1);
}
</style>