<template>
  <div class="modal" id="ofModal" tabindex="-1" aria-labelledby="ofModalLabel" aria-hidden="true">
    <div class="modal-dialog px-sm-4">
      <div class="modal-content">
        <div class="modal-header p-4 mb-2">
          <h2 class="modal-title" id="ofModalLabel">{{ ofRef['production_order'] }} </h2>
          <h5 v-if='ofRef["suspended"]' class="modal-title ms-3 badge bg-warning"> <i
              class="mx-1 bi bi-exclamation-triangle"></i> Suspensa</h5>
          <h5 v-if='ofRef["cancelled"]' class="modal-title ms-3 badge bg-secondary"> <i class="mx-1 bi bi-ban"></i>
            Cancelada</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body m-0 p-0">

          <div class="row row-fluid h-100 p-0 m-0">

            <div class="col-md-8">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                    type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Ficha</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                    type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Operação</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                    type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"
                    disabled>Volumes</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active p-3" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                  tabindex="0">
                  Cliente | Produto | Pedido | Comentários

                  <div v-if="ofRef && ofRef['product']['colors'].length > 0">
                    <h6 class="mt-2">Cores:</h6>
                    <div class="my-1 d-flex flex-wrap">
                      <div v-for="color in ofRef['product']['colors']" :key="color.id">
                        <p class="me-1 mb-1 badge color-badge text-black"
                          :style="{ backgroundColor: color.color.hex_color, border: !isTextColorBlack(color.color.cmyk_color) ? `1px solid ${color.color.hex_color}` : '1px solid #cccccc' }"
                          :class="{ 'text-white': !isTextColorBlack(color.color.cmyk_color) }">
                          P-{{ color.color.pantone_code }}
                        </p>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                  tabindex="0">
                  Maquinário | Operdores
                </div>
                <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab"
                  tabindex="0">
                  Volumes | Remessas | Notas
                </div>
              </div>
            </div>

            <div class="col-md-4 resume p-3">
              oi
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from 'vue';
import { toRef } from 'vue';


export default {
  name: 'ofModal',
  props: ['of'],
  setup(props) {
    const ofRef = toRef(props, 'of');

    function cmykToRgb(c, m, y, k) {
      // Certifique-se de que os valores estejam no intervalo correto (0 a 100 para CMYK)
      c = Math.min(100, Math.max(0, c));
      m = Math.min(100, Math.max(0, m));
      y = Math.min(100, Math.max(0, y));
      k = Math.min(100, Math.max(0, k));

      // Converta os valores de CMYK para o intervalo de 0 a 1
      c = c / 100;
      m = m / 100;
      y = y / 100;
      k = k / 100;

      // Calcule os valores de RGB
      let r = Math.round(255 * (1 - c) * (1 - k));
      let g = Math.round(255 * (1 - m) * (1 - k));
      let b = Math.round(255 * (1 - y) * (1 - k));
      return [r, g, b];
    }

    function isTextColorBlack(background_color, threshold = 160) {
      const cmykArray = background_color
        .slice(1, -1)
        .split(",")
        .map(Number);

      const rgb_background_color = cmykToRgb(cmykArray[0], cmykArray[1], cmykArray[2], cmykArray[3]);
      const [r, g, b] = rgb_background_color;
      const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b);
      console.log(luminance);
      return luminance > threshold;
    }

    const updateOfValue = () => {
      ofRef.value = 'Novo valor';
    };

    return {
      ofRef,
      updateOfValue,
      isTextColorBlack,
    };
  },
}


</script>

<style scoped>
.resume {
  background-color: var(--template-bg);
  border-bottom-left-radius: var(--bs-border-radius-lg);
  border-bottom-right-radius: var(--bs-border-radius-lg);
}

.modal-dialog {
  max-width: 750px;
}

.modal-content {
  height: 580px;
  max-height: 80vh;
  margin-bottom: 3rem !important;
  min-height: 400px;
}

@media (min-width: 768px) {
  .resume {
    border-radius: 0 0 var(--bs-border-radius-lg) 0;
  }
}

.color-badge {
  border-radius: 50px;
  font-size: 0.75rem;
}
</style>