<template>

  <body :data-bs-theme="theme">
    <!--  Body Wrapper -->
    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6"
      :data-sidebartype="isMenuVisible ? 'full' : 'mini-sidebar'" data-sidebar-position="fixed"
      data-header-position="fixed">

      <!--  Header -->
      <aside class="sidebar left-sidebar" id="side-menu" :class="{ 'd-none': !isMenuVisible }">
        <div class="brand-logo d-flex align-items-center justify-content-center">
          <HeaderLogo :theme="theme" />
        </div>
        <SideBar :id="'1'" @logout="redirectToLogin" @changeUnit="openChangeUnitModal"
          @config="openConfigModal(false, 0)" />
      </aside><!-- Fim do Header -->

      <!-- off-canvas -->
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <div class="w-100 d-flex justify-content-center">
            <HeaderLogo :theme="theme" />
          </div>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body py-0">
          <SideBar :id="'2'" @logout="redirectToLogin" @changeUnit="openChangeUnitModal(true)"
            @config="openConfigModal(true, 0)" />
        </div>
      </div><!-- Fim do off-canvas -->

      <!--  Main wrapper -->
      <div class="body-wrapper">

        <!--  Header Start -->
        <app-header :theme="theme" @change-theme="changeTheme" @hide-menu="hideMenu" @logout="redirectToLogin"
          @openSystemAbout="openSystemAbout" @openProfile="openConfigModal(false, 1)" @openBugReport="openBugReport"
          :isMenuVisible="isMenuVisible"></app-header>
        <!--  Header End -->

        <div class="content-body p-0">
          <main>
            <router-view @notifyError="notifyError" @playSound="playSound"></router-view>

          </main>
        </div>
      </div><!-- Fim do Main wrapper -->
      <ofModal :of="of" />
      <changeUnitModal @change-unit="changeUnit" :accessUnits="accessUnits" />

      <configModal 
      :tabToShow="configTab" 
      :systemSounds="systemSounds" 
      :theme="theme" 
      :accessUnits="accessUnits" 
      :currentUnit="currentUnit"
      @changeTheme="changeTheme" 
      @toggle-system-sounds="toggleSystemSounds" 
      @toggleConfigModal="toggleConfigModal" 
      @playSound="playSound" 
      @notifyError="notifyError" 
      @notifySuccess="notifySuccess" />

      <infoModal :title="'About the system'" :content="`<div class=\'d-flex justify-content-center\'><img class=\'my-3\' src=\'${require('../../assets/images/igzan_login.png')}\' alt=\'\'/></div> <p class=\'mt-2\'>The Kahun system was developed by IGZAN, a subsidiary brand of Humanart Agência de Comunicação Ltda., a Brazilian company based in the state of São Paulo. Humanart is the controller of the source code and holds the copyrights to the system.</p><p>For more information, visit the <a target=\'_blank\' href=\'https://humanart.com.br/\'>Humanart website</a>. The use of this system is subject to terms of use and privacy policies.</p><p class=\'text-primary\'>Version Alpha 1.0.0</p>`
    " :id="'aboutSystemModal'"></infoModal>

      <firstAccessNotesModal />
      <bugReportModal @playSound="playSound" />
    </div><!-- Fim do Body Wrapper -->
  </body>
</template>

<script>
import SideBar from "../components/SideBar.vue";
import AppHeader from "../components/Header.vue";
import HeaderLogo from "../components/HeaderLogo.vue";
import { userPreferencesStore } from '../../store/preferencesStore';
import { storeToRefs } from 'pinia';
import { ref, toRef } from 'vue';
import { onMounted, watchEffect } from "vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import ofModal from "../components/Modals/OfModal.vue";
import changeUnitModal from '../components/Modals/ChangeUnit.vue'
import configModal from '../components/Modals/ConfigModal.vue'
import infoModal from "../components/Modals/InfoModal.vue";
import firstAccessNotesModal from "../components/Modals/FirstAccessNotesModal.vue";
import bugReportModal from "../components/Modals/BugReportModal.vue";
import http from '@/services/http'
import { inject } from 'vue'
import { Howl } from 'howler';
import * as bootstrap from 'bootstrap';


export default {
  name: "IndexPage",
  components: {
    SideBar,
    AppHeader,
    HeaderLogo,
    ofModal,
    changeUnitModal,
    configModal,
    infoModal,
    firstAccessNotesModal,
    bugReportModal,
  },
  emits: ['logout', 'changeUnit', 'config', 'toggle-system-sounds', 'change-unit', 'changeTheme', 'hide-menu', 'playSound', 'notifyError', 'toggleConfigModal', 'notifySuccess'],

  setup() {
    const store = userPreferencesStore();
    const theme = storeToRefs(store).theme;
    const isMenuVisible = toRef(localStorage.getItem('showMenu') === 'true');
    // let ofModalElement;
    let of = ref('')
    const $cookies = inject('$cookies');
    let changeUnitModalElement;
    let configModalElement;
    const configTab = ref(0);
    const systemSounds = storeToRefs(store).systemSounds;
    const accessUnits = ref([]);
    const currentUnit = ref(null)

    const redirectToLogin = async () => {
      try {
        const logout = async () => {
          try {
            const response = await http.post('/logout');
            if (response.status === 200) {
              window.location.href = '/login';
              localStorage.clear();
              $cookies.remove('refresh_token');
            }
          } catch (error) {
            console.error('Erro na requisição de logout:', error);
          }
        };
        await logout();
      } catch (error) {
        console.error('Erro no bloco try principal:', error);
      }
    }

    const playSound = (mood) => {
      let sound = null;

      if (mood === 'confirm') {
        sound = new Howl({
          src: [require('@/../assets/sounds/confirm.wav')]
        })
      } else if (mood === 'achivement') {
        sound = new Howl({
          src: [require('@/../assets/sounds/achivement.wav')]
        })
      } else if (mood === 'notification') {
        sound = new Howl({
          src: [require('@/../assets/sounds/notification.wav')]
        })
      } else if (mood === 'mistake') {
        sound = new Howl({
          src: [require('@/../assets/sounds/mistake.wav')]
        })
      } else if (mood === 'somethingWeird') {
        sound = new Howl({
          src: [require('@/../assets/sounds/something-weird.wav')]
        })
      } else if (mood === 'accessDenied') {
        sound = new Howl({
          src: [require('@/../assets/sounds/access-denied.wav')]
        })
      } else if (mood === 'error') {
        sound = new Howl({
          src: [require('@/../assets/sounds/error.wav')],
          volume: 0.7
        })
      } else if (mood === 'something') {
        sound = new Howl({
          src: [require('@/../assets/sounds/something.wav')],
        })
      } else if (mood === 'intro') {
        sound = new Howl({
          src: [require('@/../assets/sounds/intro.wav')],
          volume: 0.8
        })
      } else if (mood === 'login') {
        sound = new Howl({
          src: [require('@/../assets/sounds/login.wav')],
        })
      }

      if (systemSounds.value === 'true' && sound) {
        sound.play();
      }

    }

    const notifyError = ({ message, id }) => {
      toast.error(message, {
        transition: toast.TRANSITIONS.SLIDE,
        autoClose: 5000,
        toastId: id,
        theme: 'colored',
        dangerouslyHTMLString: true,
        style: {
          theme: 'dark',
          opacity: '1',
          userSelect: 'initial',
          top: '75px',
        }
      }); // ToastOptions
    };

    const notifySuccess = ({ message, id }) => {
      toast.success(message, {
        transition: toast.TRANSITIONS.SLIDE,
        autoClose: 5000,
        toastId: id,
        theme: 'colored',
        dangerouslyHTMLString: true,
        style: {
          theme: 'dark',
          opacity: '1',
          userSelect: 'initial',
          top: '75px',
        }
      }); // ToastOptions
    };


    const changeTheme = async () => {

      store.changeTheme();

      try {
        const data = {
          default_theme: store.theme
        }
        await http.post('/user_preferences', data);
      } catch (error) {
        console.log(error);
      }
    };

    const toggleSystemSounds = async (allow) => {

      store.setSystemSounds(allow);

      try {
        const data = {
          allow_system_sounds: allow
        }
        await http.post('/user_preferences', data);
        localStorage.setItem('systemSounds', allow);
      } catch (error) {
        console.log(error);
      }
    };

    const getUserRoles = async () => {
      const unitId = JSON.parse(localStorage.getItem('user_roles')).unit_id;
      const response = await http.get('/unit_user_roles/' + unitId);

      if (response.status === 200) {
        window.localStorage.setItem('user_roles', JSON.stringify(response.data));
      }
    };

    const getAccessUnits = async (filterCurrentUnit = true) => {
      try {

        const response = await http.get('/business_units');

        if (response.status === 200) {
          const currentUnit = JSON.parse(localStorage.getItem('user_roles')).unit_id;

          accessUnits.value = response.data;
          if (filterCurrentUnit) {
            accessUnits.value = response.data.filter(unit => unit.id !== currentUnit);
          }
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    }

    const openChangeUnitModal = async (closeOffCanvas = false) => {
      const offcanvasElement = document.getElementById('offcanvasExample');
      const closeButton = offcanvasElement.querySelector('.btn-close');

      if(getAccessUnits()) {
        changeUnitModalElement.show();

      } else {
        notifyError({ message: 'Erro ao obter unidades de acesso. Por favor, tente novamente mais tarde.', id: 5 });
      }

      if (closeOffCanvas) {
        closeButton.click();
      }
    }

    const changeUnit = async (unit) => {

      try {
        const response = await http.get('/unit_user_roles/' + unit);

        if (response.status === 200) {
          localStorage.setItem('user_roles', JSON.stringify(response.data));
          localStorage.setItem("play_logoff", true);
          window.location.reload();
        }

      } catch (error) {
        console.log(error);
      }
    }

    const openConfigModal = (closeOffCanvas = false, tabToShow) => {
      configTab.value = tabToShow;
      console.log(configTab.value)
      getAccessUnits(false);
      currentUnit.value = JSON.parse(window.localStorage.getItem('user_roles'))['unit_id']

      const offcanvasElement = document.getElementById('offcanvasExample');
      const closeButton = offcanvasElement.querySelector('.btn-close');

      if (closeOffCanvas) {
        closeButton.click();
      }

      watchEffect(() => {
        if (configTab.value === tabToShow) {
          configModalElement.show();
        }
      });
    }

    const toggleConfigModal = (tabToShow = 0) => {
      configTab.value = tabToShow;
      configModalElement.toggle();
    }

    onMounted(() => {
      changeUnitModalElement = new bootstrap.Modal(document.getElementById('changeUnitModal'));
      configModalElement = new bootstrap.Modal(document.getElementById('configModal'));
      const firstAccessModal = new bootstrap.Modal(document.getElementById('WelcomeModal'));

      if (localStorage.getItem('play_intro')) {
        setTimeout(() => {
          localStorage.removeItem('play_intro');
          playSound('login');
        }, 500);
      } else if (localStorage.getItem('play_logoff')) {
        localStorage.removeItem('play_logoff');
        playSound('achivement');
      }

      if (localStorage.getItem('showMenu') === null) {
        localStorage.setItem('showMenu', true);
        isMenuVisible.value = true;
      }

      if (localStorage.getItem('showMenu') === false) {
        localStorage.setItem('showMenu', false);
        isMenuVisible.value = false;
      }

      if (localStorage.getItem('first_access')) {
        firstAccessModal.show();
      }

      getUserRoles();



      // ofModalElement = new bootstrap.Modal(document.getElementById('ofModal'))

      const offcanvasElement = document.getElementById('offcanvasExample');
      const closeButton = offcanvasElement.querySelector('.btn-close');

      offcanvasElement.addEventListener('hide.bs.offcanvas', function () {
        closeButton.click();
      });

    });

    const hideMenu = () => {
      const SideMenu = document.getElementById("side-menu");
      SideMenu.classList.toggle("d-none");

      isMenuVisible.value = !isMenuVisible.value;
      store.setShowMenu(isMenuVisible.value);
      localStorage.setItem('showMenu', isMenuVisible.value);

      const PageWrapper = document.querySelector(".page-wrapper");
      if (SideMenu.classList.contains("d-none")) {
        PageWrapper.setAttribute("data-sidebartype", "mini-sidebar");
      } else {
        PageWrapper.setAttribute("data-sidebartype", "full");
      }
    };

    return {
      isMenuVisible, // Retornado isMenuVisible no objeto de retorno
      changeTheme,
      hideMenu,
      theme,
      store,
      notifyError,
      notifySuccess,
      of,
      redirectToLogin,
      playSound,
      openChangeUnitModal,
      openConfigModal,
      configTab,
      systemSounds,
      toggleSystemSounds,
      changeUnit,
      accessUnits,
      toggleConfigModal,
      currentUnit
    };
  },

  methods: {
    openSystemAbout() {
      const modal = document.getElementById("aboutSystemModal");
      const instance = bootstrap.Modal.getOrCreateInstance(modal);
      instance.show();
    },

    openBugReport() {
      const modal = document.getElementById("bugReportModal");
      const instance = bootstrap.Modal.getOrCreateInstance(modal);
      instance.show();
    }
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body-wrapper {
  background-color: var(--template-bg);
  min-height: 100vh;
}

header {
  background-color: var(--bs-body-bg) !important;
  /* z-index: 1000; */
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.content-body {
  padding-top: 70px !important;
}

.container-nav {
  height: 100%;
}

.offcanvas-header {
  max-height: 70px;
}

aside div,
.left-sidebar {
  background-color: var(--bs-body-bg) !important;
}

.left-sidebar {
  border-right: 1px solid var(--bs-secondary-border-subtle);
}

#offcanvasExample {
  width: 275px;
}
</style>
