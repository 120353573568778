<template>
    <div class="container-fluid bg-secondary w-100 py-5 px-4">
        <div class="container d-flex justify-content-between px-4 flex-wrap">
            <h1 class="text-white">Meu aprendizado</h1>
            <div v-if="showAddCourse()">
                <button @click="openCourseModal(true)" class="btn btn-lg btn-outline-light me-auto"><i
                        class="bi bi-plus-lg me-2"></i>Adicionar curso</button>
            </div>
        </div>
    </div>
    <div class="container-fluid p-4 mt-4  ">
        <div class="container">
            <div v-if="loading" class="d-flex align-items-center">
                <div class="spinner-border mx-auto text-primary mb-4" role="status" aria-hidden="true"></div>
            </div>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 gx-3">
                <CourseCard v-for="course in courses" v-bind:key="course.id" :courseData="course"
                    :editable="showAddCourse()" @notifyError="this.$emit('notifyError', $event)"
                    @editCourse="editCourse"></CourseCard>
            </div>
        </div>

    </div>

    <courseModal @notifyError="this.$emit('notifyError', $event)" @playSound="$emit('playSound', $event)"
        @sendCourse="sendCourse($event)" @toggleCourseModal="toggleCourseModal" :course="courseRef"></courseModal>
</template>


<script>
import CourseCard from './CourseCard.vue';
import http from "@/services/http"
import { onMounted, ref, nextTick } from 'vue'
import courseModal from '@/components/Modals/CourseModal.vue'
import * as bootstrap from 'bootstrap';


export default {
    name: 'CoursesPage',
    components: {
        CourseCard,
        courseModal
    },
    emits: ['notifyError', 'playSound', 'toggleCourseModal', 'editCourse', 'sendCourse'],
    setup(props, { emit }) {
        const courses = ref([]);
        const userAdmin = window.localStorage.getItem('user_admin') ? window.localStorage.getItem('user_admin') : false;
        let courseModalElement;
        let userRoles = null;
        const courseRef = ref(null);
        const loading = ref(true);

        userRoles = JSON.parse(window.localStorage.getItem('user_roles'))

        const openCourseModal = (isNew = false) => {
            document.body.style.cursor = 'progress';
            if (isNew) {
                courseRef.value = null;
            }
            nextTick(() => {
                courseModalElement.show();
            });
            document.body.style.cursor = 'default';
        };

        const toggleCourseModal = () => {
            courseModalElement.toggle();
        };

        const hideCourseModal = () => {
            courseModalElement.hide();
        }

        const connectionFail = () => {
            emit('notifyError', { message: "<h5 class='fw-bold text-white mb-2'>Falha ao conectar!</h5><p class='text-white mb-0'>Verifique a sua conexão com a rede</p>", id: 1 });
        };

        const getCourses = async () => {
            try {
                loading.value = true;
                const { data } = await http.get('/courses');
                courses.value = data;
                loading.value = false;

            } catch (error) {
                connectionFail();
                setTimeout(() => {
                    getCourses();
                }, 5000);
            }
        };

        const editCourse = async (course) => {
            try {
                document.body.style.cursor = 'progress';
                const response = await http.get('/course/' + course);

                if (response.status === 200) {
                    const { data } = response;
                    courseRef.value = data;
                    openCourseModal();
                }

            } catch (error) {
                connectionFail();
                document.body.style.cursor = 'default';
            }
        };

        const showAddCourse = () => {
            if (userAdmin === 'true' || userRoles['user_roles']['roles'].includes('create-course')) {
                return true;
            }
            return false;
        };

        const sendCourse = async (course) => {
            loading.value = true;
            try {
                hideCourseModal();
                const course_id = courseRef.value ? `/${courseRef.value.id}` : '';
                const response = await http.post('/course' + course_id, course, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });


                if (response.status === 200) {
                    courses.value = [];
                    getCourses();
                    emit('toggleCourseModal');
                }

            } catch (error) {
                toggleCourseModal();
                loading.value = false;
                connectionFail();
            }
        };

        onMounted(() => {
            getCourses();
            courseModalElement = new bootstrap.Modal(document.getElementById('courseModal'));
        });

        return {
            courses,
            showAddCourse,
            openCourseModal,
            toggleCourseModal,
            courseRef,
            editCourse,
            sendCourse,
            loading
        };
    }

}
</script>



<style scoped></style>