<template>
  <!-- ======= Sidebar Items ======= -->

  <ul class="sidebar-nav w-100 mt-3" :id="'sidebar-nav' + id">

    <li class="nav-item">
      <a class="nav-link collapsed" :id="'learning-nav-link' + id"
        @click="handleCollapseMenu('learning-nav-link' + id, 'components-nav' + id)">
        <i class="bi bi-mortarboard"></i><span
          :class="{ 'active': this.$route.path.startsWith('/learning') }">Aprendizado</span><i
          class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul :id="'components-nav' + id" class="nav-content collapse">
        <div>
          <li>
            <a href="/learning">
              <i class="bi bi-circle"></i><span
                :class="{ 'active': this.$route.path == '/learning' }">Treinamentos</span>
            </a>
          </li>
        </div>
      </ul>
    </li><!-- End production Nav -->

    <li class="nav-item" v-if="menuShouldBeShown('Financeiro')">
      <a class="nav-link collapsed" :id="'finance-nav-link' + id"
        @click="handleCollapseMenu('finance-nav-link' + id, 'finance-nav' + id)">
        <i class="bi bi-cash-coin"></i><span
          :class="{ 'active': this.$route.path.startsWith('/finance') }">Financeiro</span><i
          class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul :id="'finance-nav' + id" class="nav-content collapse " :data-bs-parent="'#sidebar-nav' + id">
        <div>
          <li>
            <a href="/finance/dashboard">
              <i class="bi bi-circle"></i><span
                :class="{ 'active': this.$route.path == '/finance/dashboard' }">Resumo</span>
            </a>
          </li>
          <li v-if="navShouldBeShown('cashflow-access')">
            <a href="/finance/cashflow">
              <i class="bi bi-circle"></i><span :class="{ 'active': this.$route.path == '/finance/cashflow' }">Fluxo de
                caixa</span>
            </a>
          </li>
        </div>
      </ul>
    </li><!-- End Finance Nav -->


    <li class="nav-item" v-if="menuShouldBeShown('Relatórios')">
      <a class="nav-link collapsed" :id="'reports-nav-link' + id"
        @click="handleCollapseMenu('reports-nav-link' + id, 'reports-nav' + id)">
        <i class="bi bi-file-earmark-bar-graph"></i><span
          :class="{ 'active': this.$route.path.startsWith('/reports') }">Relatórios</span><i class="bi bi-chevron-down ms-auto"></i>
      </a>
      <ul :id="'reports-nav' + id" class="nav-content collapse " :data-bs-parent="'#sidebar-nav' + id">
        <div>
          <li>
            <a href="/reports/finance">
              <i class="bi bi-circle"></i><span
                :class="{ 'active': this.$route.path == '/reports/finance' }">Financeiros</span>
            </a>
          </li>
          <li>
            <a href="/reports/sales">
              <i class="bi bi-circle"></i><span
                :class="{ 'active': this.$route.path == '/reports/sales' }">Vendas</span>
            </a>
          </li>
        </div>
      </ul>
    </li><!-- End Relatórios Nav -->

  </ul>


  <div class="sticky-bottom bg-body p-0">

    <hr />
    <button class="accordion-button collapsed bottom-btn" type="button" @click="this.$emit('config')">
      <span>
        <i class="bi bi-gear"></i>
      </span>
      <span>Configurações</span>
    </button>

    <button v-if="changeUnitShouldBeShown()" class="accordion-button collapsed bottom-btn" type="button"
      @click="this.$emit('changeUnit')">
      <span>
        <i class="bi bi-arrow-left-right"></i>
      </span>
      <div class="v-stack">
        <span class="d-block">Mudar de unidade</span>
        <span class="small text-primary">{{ currentUnitName }}</span>
      </div>
    </button>

    <button @click="redirectToLogin" class="accordion-button collapsed bottom-btn logout" type="button">
      <span>
        <i class="bi bi-box-arrow-left"></i>
      </span>
      <span>Logout</span>
    </button>
  </div>

</template>

<script>
import { toRefs, reactive } from 'vue'

export default {
  name: 'SideBarItems',
  props: {
    id: {
      type: String,
      required: true
    }

  },
  emits: ['logout', 'changeUnit', 'config'],

  setup(props, ctx) {

    const redirectToLogin = async () => {
      ctx.emit('logout');
    }

    let userRoles = null;
    let currentUnitName = null;
    let multiAccess = null;

    try {
      userRoles = JSON.parse(window.localStorage.getItem('user_roles'));
      currentUnitName = userRoles['nickname'];
      multiAccess = window.localStorage.getItem('multi_access');
    } catch (error) {
      redirectToLogin();
    }

    const userAdmin = window.localStorage.getItem('user_admin') ? window.localStorage.getItem('user_admin') : false;


    const state = reactive({
      userRoles,
      userAdmin,
      currentUnitName,
      multiAccess
    });

    const reactiveRefs = toRefs(state);

    const navShouldBeShown = (privilege) => {
      if (reactiveRefs.userAdmin.value === 'true' || reactiveRefs.userRoles.value['user_roles']['roles'].includes(privilege)) {
        return true;
      }
      return false;

    }

    const menuShouldBeShown = (category) => {
      if (reactiveRefs.userAdmin.value === 'true' || reactiveRefs.userRoles.value['user_roles']['departments'].includes(category)) {
        return true;
      }
      return false;
    }

    const changeUnitShouldBeShown = () => {
      if (reactiveRefs.userAdmin.value === 'true' || reactiveRefs.multiAccess.value === 'true') {
        return true;
      }
      return false;
    }


    return {
      userRoles,
      userAdmin,
      navShouldBeShown,
      menuShouldBeShown,
      changeUnitShouldBeShown,
      ...reactiveRefs,
      redirectToLogin
    }

  },

  methods: {

    handleCollapseMenu(origin, target) {
      const allNavs = document.querySelectorAll('.nav-content');

      for (let i = 0; i < allNavs.length; i++) {
        if (allNavs[i].id !== target) {
          allNavs[i].classList.add('collapse');
        }
      }

      const allButtons = document.querySelectorAll('.nav-link');

      for (let i = 0; i < allButtons.length; i++) {
        if (allButtons[i].id !== origin) {
          allButtons[i].classList.add('collapsed');
        }
      }

      const menuButton = document.getElementById(origin);
      const menuItem = document.getElementById(target);
      menuButton.classList.toggle('collapsed');
      menuItem.classList.toggle('collapse');
    },


  }


}
</script>

<style scoped>
.sidebar-nav {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  user-select: none;
}


.nav-content a {
  color: var(--bs-tertiary);
}

.nav-content a:hover {
  color: var(--bs-emphasis-color);
  font-weight: 600 !important;
}

.nav-content a span:hover:not(.active) {
  font-weight: 600 !important;
}

i {
  margin-right: 1rem;
  font-size: 1.5rem;
}

.nav-link {
  height: 46px;
  width: 100%;
}

.active {
  color: var(--bs-primary) !important;
}

.nav-item {
  margin-bottom: 0.5rem;
}

.accordion-button {
  background-color: transparent !important;
  border-width: 0px !important;
  border-radius: 5px !important;
  margin-bottom: 0.3rem;
  padding: 0.5rem 0.8rem !important;
}

.accordion-button:hover,
.accordion-button:not(.collapsed) {
  background-color: rgba(var(--bs-primary-rgb), 0.5) !important;
  color: var(--bs-emphasis-color) !important;
}

.accordion-button:not(.collapsed) span {
  font-weight: 600 !important;
}

.bottom-btn:hover {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.1) !important;
  cursor: pointer;
}

.logout:hover span {
  color: var(--bs-danger) !important;
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  margin-bottom: 5px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-weight: 300;
  color: var(--bs-emphasis-color);
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link.collapsed {
  color: var(--bs-emphasis-color);
  background: transparent;
}

.sidebar-nav .nav-link:not(.collapsed) {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  font-weight: 600 !important;
}

.sidebar-nav .nav-link:not(.collapsed) i {
  color: var(--bs-primary);
}

.sidebar-nav .nav-link:hover {
  color: var(--bs-emphasis-color);
  background: rgba(var(--bs-primary-rgb), 0.2);
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
  font-size: 1rem;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.nav-content {
  padding: 0;
  margin: 0;
  /* margin-bottom: 15px; */
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-weight: 300;
  padding: 10px 0 10px 25px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
}

.sidebar-nav .nav-content a.active {
  color: var(--bs-primary);
}

.sidebar-nav .nav-content a.active i {
  background-color: var(--bs-primary);
  font-weight: 600 !important;

}

.active+.bi-circle {
  color: var(--bs-primary) !important;
}


span.active {
  font-weight: 600 !important;
}

.nav-content {
  display: grid !important;
  grid-template-rows: 0fr;
  transition: grid-template-rows 350ms;
}

.nav-content:not(.collapse) {
  grid-template-rows: 1fr;
}

.nav-content:not(.collapse)>div {
  padding-bottom: 7px;
}


.nav-content>div {
  overflow: hidden;
}
</style>