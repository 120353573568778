import { defineStore } from 'pinia';

export const userPreferencesStore = defineStore('preferences', {
  state() {
    return {
      theme: localStorage.getItem('theme') || 'light',
      showMenu: localStorage.getItem('showMenu') || true,
      systemSounds: localStorage.getItem('systemSounds') || true,
    }
  },
  actions: {
    setUserTheme(theme = 'light') {
      this.theme = theme
    },
    changeTheme() {
      this.theme = this.theme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', this.theme);
    },
    setShowMenu(showMenu = false) {
      this.showMenu = showMenu;
      localStorage.setItem('showMenu', showMenu);
    },
    setSystemSounds(systemSounds) {
      this.systemSounds = systemSounds;
      localStorage.setItem('systemSounds', systemSounds);
    },
  },
  getters: {
    getTheme() {
      return this.theme;
    },
    getShowMenu() {
      return this.showMenu;
    },
    getSystemSounds() {
      return this.systemSounds;
    },
  },
});
