<template>
    <div class="modal fade" id="instructorModal" tabindex="-1" aria-labelledby="instructorModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="instructorModalLabel">Criar novo instrutor</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body pt-0">
                    <form class="p-3 pt-0" @submit.prevent="createInstructor">
                        <div class="flex-column align-items-center justify-content-center pt-3">

                            <div class="col col-profile mb-3 mx-auto"
                                style="max-width: fit-content; position: relative;">

                                <div v-if="!loadingPicture" class="justify-content-center align-items-center hover-div"
                                    id="update-profile-picture" @click="updateProfilePicture">
                                    <h5 class="modal-title text-white d-none">Alterar</h5>
                                </div>

                                <div v-else class="spinner justify-content-center align-items-center">
                                    <div class="spinner-border text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <img src="../../../assets/images/user_default.jpg" alt="profile-picture" width="110"
                                    height="110" class="rounded-circle" id="instructor-profile-picture-settings" />
                            </div>
                            <button v-if="file" type="button" class="btn btn-outline-warning mt-3" @click="removeProfilePicture">Remover foto</button>

                            <div class="col pt-3">
                                <div class="form-floating mb-3" style="min-width: 200px;">
                                    <input type="text" class="form-control need-validation not-null"
                                        :class="{ 'is-invalid': invalidInputs.includes('instructor-name-ipt') }"
                                        @input="removeInvalid" id="instructor-name-ipt" placeholder="Nome">
                                    <label for="name-ipt">Nome</label>
                                    <div class="invalid-feedback ms-2">
                                        Campo obrigatório
                                    </div>
                                </div>
                                <div class="form-floating mb-3" style="min-width: 200px;">
                                    <input type="text" class="form-control need-validation not-null"
                                        :class="{ 'is-invalid': invalidInputs.includes('instructor-surname-ipt') }"
                                        @input="removeInvalid" id="instructor-surname-ipt" placeholder="Sobrenome">
                                    <label for="surname-ipt">Sobrenome</label>
                                    <div class="invalid-feedback ms-2">
                                        Campo obrigatório
                                    </div>
                                </div>

                                <div class="form-floating mb-0">
                                    <textarea class="form-control mb-0 need-validation not-null"
                                        :class="{ 'is-invalid': invalidInputs.includes('instructor-floating-textarea') }"
                                        @input="removeInvalid" placeholder="Leave a comment here"
                                        id="instructor-floating-textarea" style="height: 100px"></textarea>
                                    <label for="instructor-floating-textarea">Curriculo</label>
                                    <div class="invalid-feedback ms-2">
                                        Campo obrigatório
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary" @click="createInstructor">Criar instrutor</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import http from "@/services/http";
import { Modal } from 'bootstrap';

export default {
    name: "instructorModal",
    emits: ['playSound', 'updateInstructors'],
    setup() {
        const loadingPicture = ref(false);
        let modal;
        let profilePicture;
        const invalidInputs = ref([]);
        let originalPicSource;
        const file = ref(null);
        let formData = new FormData();

        const updateProfilePicture = () => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/jpg, image/jpeg, image/png';
            fileInput.onchange = async function (e) {
                file.value = e.target.files[0];

                if (file.value) {
                    formData.append('profile_picture', file.value);
                    loadingPicture.value = true;
                    try {
                        profilePicture.src = URL.createObjectURL(file.value);
                    } catch (error) {
                        console.error(error);
                    }
                    loadingPicture.value = false;
                }
            };
            fileInput.click();
        };

        const removeProfilePicture = () => {
            profilePicture.src = originalPicSource;
            file.value = null;
            formData.delete('profile_picture');
        };

        onMounted(() => {
            modal = document.getElementById("instructorModal");
            profilePicture = document.getElementById("instructor-profile-picture-settings");
            const nameInput = document.getElementById("instructor-name-ipt");
            const surnameInput = document.getElementById("instructor-surname-ipt");
            const curriculumTextarea = document.getElementById("instructor-floating-textarea");
            originalPicSource = profilePicture.src;

            modal.addEventListener("hidden.bs.modal", () => {
                profilePicture.src = originalPicSource;
                nameInput.value = "";
                surnameInput.value = "";
                curriculumTextarea.value = "";
                invalidInputs.value = [];
                formData.delete('profile_picture');
                formData.delete('data');
                file.value = null;
            })

        });

        return {
            loadingPicture,
            updateProfilePicture,
            invalidInputs,
            originalPicSource,
            file,
            removeProfilePicture,
            formData
        };
    },

    methods: {
        checkValidity(input) {
            if (input.classList.contains("not-null")) {
                if (input.value.trim() === "" && !this.invalidInputs.includes(input.id)) {
                    this.invalidInputs.push(input.id);
                } else if (input.value.trim() !== "" && this.invalidInputs.includes(input.id)) {
                    this.invalidInputs.splice(this.invalidInputs.indexOf(input.id), 1);
                }
            }
        },

        validateInputs() {
            const inputs = document.querySelectorAll(".need-validation");

            inputs.forEach((input) => {
                this.checkValidity(input);
            });

            if (this.invalidInputs.length > 0) {
                this.$emit('playSound', 'mistake');
                return false;
            } else {
                return true;
            }
        },

        removeInvalid(event) {
            const targetId = event.target.id;
            if (this.invalidInputs.includes(targetId)) {
                this.invalidInputs.splice(this.invalidInputs.indexOf(targetId), 1);
            }
        },

        async createInstructor() {
            const valid = this.validateInputs();
            if (valid) {
                const nameInput = document.getElementById("instructor-name-ipt");
                const surnameInput = document.getElementById("instructor-surname-ipt");
                const curriculumTextarea = document.getElementById("instructor-floating-textarea");
                const modal = Modal.getInstance(document.getElementById("instructorModal"));

                this.formData.append("data", JSON.stringify({ name: nameInput.value, surname: surnameInput.value, curriculum: curriculumTextarea.value }));

                try {
                    const response = await http.post('/create_instructor', this.formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (response.status === 200) {
                        modal.hide();
                    } 
                } catch (error) {   
                    console.log(error); 
                }
                this.$emit('updateInstructors');
            }

        }
    }
};

</script>


<style scoped>
input {

    -webkit-autofill,
    -webkit-autofill:hover,
    -webkit-autofill:focus,
    -webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 1000px white inset;
    }
}

input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
}


.spinner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 110px;
    height: 110px;
}

.hover-div {
    position: absolute;
    width: 110px;
    height: 110px;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    cursor: pointer;
    border-radius: 100px;
}

.hover-div:hover {
    opacity: 1;
}

.hover-div:hover h5 {
    display: block !important;
}
</style>