<template>
  <div class="login-form-container w-100 h-100">
    <div class="overlay p-5">
      <LoginForm class="mx-auto" @notifyError="notifyError" @notifySuccess="NotifySuccess"/>
    </div>
  </div>
</template>

<script>
import LoginForm from "../components/LoginForm.vue"
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  name: 'LoginPage',
  components: {
    LoginForm
  },
  emits: ['notifyError', 'notifySuccess'],
  setup() {
    const notifyError = ({ message, id }) => {
      toast.error(message, {
        transition: toast.TRANSITIONS.SLIDE,
        autoClose: 5000,
        toastId: id,
        theme: 'colored',
        dangerouslyHTMLString: true,
        style: {
          theme: 'dark',
          opacity: '1',
          userSelect: 'initial',
          top: '35px',
        }
      }); // ToastOptions
    };

    const NotifySuccess = ({ message, id }) => {
      toast.success(message, {
        transition: toast.TRANSITIONS.SLIDE,
        autoClose: 10000,
        toastId: id,
        theme: 'colored',
        dangerouslyHTMLString: true,
        style: {
          theme: 'dark',
          opacity: '1',
          userSelect: 'initial',
          top: '35px',
        }
      }); // ToastOptions
    };

    return {
      notifyError,
      NotifySuccess
    }

  },

  mounted() {
    // verify if "recovered" is in the URL params
    if (this.$route.query.recovered) {
      this.NotifySuccess({ message: "<h5 class='fw-bold text-white mb-2'>Sucesso!</h5><p class='text-white mb-0'>Sua senha foi redefinida com sucesso!</p>", id: 1 })
    }
  }
}

</script>


<style scoped>
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  text-align: center;
  min-height: 100vh;
}

.login-form-container {

  min-height: 100vh;
  height: 100%;
  background: linear-gradient(90deg, rgba(var(--bs-primary-rgb), 0.75) 0%, rgba(var(--bs-success-rgb), 0.5) 100%), url(../../assets/images/backgrounds/login_background.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

</style>