<template>
    <div class="modal fade" id="configModal" tabindex="-1" aria-labelledby="configModalLabel" aria-hidden="true">
        <div class="modal-dialog px-sm-4 p-3" style="max-width: 590px; min-width: 350px;">
            <div class="modal-content">
                <div class="modal-header p-4 pb-3">
                    <h4 class="modal-title" id="configModalLabel">Configurações</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body pt-0">


                    <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                            <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                                aria-selected="true">Sistema</button>
                            <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile"
                                aria-selected="false">Perfil</button>
                            <button v-if="userAdmin" class="nav-link" id="nav-users-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-users" type="button" role="tab" aria-controls="nav-users"
                                aria-selected="false">Usuários</button>
                        </div>
                    </nav>
                    <div class="tab-content p-3" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-home" role="tabpanel"
                            aria-labelledby="nav-home-tab" tabindex="0">

                            <div class="form-check form-switch mt-3 mb-4">
                                <input class="form-check-input" type="checkbox" role="switch" id="switchSoundSystem"
                                    @input="$emit('toggle-system-sounds', $event.target.checked)"
                                    :checked="systemSounds == 'true'">
                                <label class="form-check-label" for="switchSoundSystem">Pemitir sons do
                                    sistema</label>
                            </div>

                            <div class="form-floating my-3">
                                <select class="form-select" id="floatingSelectTheme" @input="this.$emit('changeTheme')"
                                    aria-label="Floating label select example">
                                    <option :selected="theme == 'dark'">Escuro</option>
                                    <option :selected="theme == 'light'" value="light">Claro</option>
                                </select>
                                <label for="floatingSelectTheme">Tema do aplicativo</label>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"
                            tabindex="1">
                            <div>
                                <div class="row flex-row align-items-start justify-content-center pt-3">



                                    <div class="col col-profile mb-3"
                                        style="max-width: fit-content; position: relative;">

                                        <div v-if="!loadingPicture"
                                            class="justify-content-center align-items-center hover-div"
                                            id="update-profile-picture" @click="updateProfilePicture">
                                            <h5 class="modal-title text-white d-none">Alterar</h5>
                                        </div>

                                        <div v-else class="spinner justify-content-center align-items-center">
                                            <div class="spinner-border text-light" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>

                                        <img :src="getProfilePicture()" alt="profile-picture" width="95" height="95"
                                            class="rounded-circle" id="profile-picture-settings" />
                                    </div>



                                    <div class="col">
                                        <div class="form-floating mb-3" style="min-width: 200px;">
                                            <input type="text" class="form-control" id="username-ipt"
                                                placeholder="username" disabled :value="basicInfo.username">
                                            <label for="username-ipt">Nome de usuário</label>
                                        </div>
                                        <div class="form-floating mb-3" style="min-width: 200px;">
                                            <input type="email" class="form-control" id="email-ipt" placeholder="e-mail"
                                                disabled :value="basicInfo.email">
                                            <label for="email-ipt">E-mail</label>
                                        </div>
                                        <div class="form-floating mb-3" style="min-width: 200px;">
                                            <input type="text" class="form-control" id="name-ipt" placeholder="Nome"
                                                v-model="basicInfo.name" @change="updateInfo('name', 'name-ipt')">
                                            <label for="name-ipt">Nome</label>
                                        </div>
                                        <div class="form-floating mb-0" style="min-width: 200px;">
                                            <input type="text" class="form-control" id="surname-ipt"
                                                placeholder="Sobrenome" v-model="basicInfo.surname"
                                                @change="updateInfo('surname', 'surname-ipt')">
                                            <label for="surname-ipt">Sobrenome</label>
                                        </div>
                                        <button type="button" class="btn btn-outline-warning mt-3 d-none">Alterar
                                            senha</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="tab-pane fade" id="nav-users" role="tabpanel" aria-labelledby="nav-users-tab"
                            tabindex="2">
                            <button class="btn btn-outline-primary w-100" @click="openInviteModal">
                                Convidar usuário para uma unidade</button>

                        </div>
                    </div>


                </div>


            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>

    <inviteUserModal 
    :accessUnits="accessUnits" 
    :currentUnit="currentUnit" 
    @notifyError="this.$emit('notifyError', $event)" 
    @playSound="$emit('playSound', $event)" 
    @notifySuccess="this.$emit('notifySuccess', $event)" />
    
</template>

<script>
import { onMounted, ref } from 'vue';
import http from "@/services/http";
import inviteUserModal from './InviteUserModal.vue';
import { Modal } from 'bootstrap';

export default {
    name: 'configModal',
    props: ['tabToShow', 'theme', 'systemSounds', 'accessUnits', 'currentUnit'],
    emits: ['changeTheme', 'toggle-system-sounds', 'toggleConfigModal', 'notifyError', 'playSound', 'notifySuccess'],
    components: {
        inviteUserModal
    },

    setup(props, ctx) {
        const basicInfo = ref(JSON.parse(localStorage.getItem("user_roles"))['basicinfo']);
        const loadingPicture = ref(false);
        const userAdmin = localStorage.getItem("user_admin");
        let inviteModalElement;

        const tabList = {
            0: { tab: 'nav-home-tab', content: 'nav-home' },
            1: { tab: 'nav-profile-tab', content: 'nav-profile' },
            2: { tab: 'nav-users-tab', content: 'nav-users' }
        };

        const updateProfilePicture = async () => {
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/jpg, image/jpeg, image/png';
            fileInput.onchange = async function (e) {
                const file = e.target.files[0];
                const profilePicture = document.getElementById("profile-picture-settings");
                const headerProfilePicture = document.getElementById("profile-picture");

                if (file) {
                    loadingPicture.value = true;
                    try {
                        const formData = new FormData();
                        formData.append('profile_picture', file);

                        const response = await http.post('/profile_picture_update', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                        if (response.status === 200) {
                            const base_url = window.location.origin;
                            const timestamp = Date.now();
                            const imageUrl = `${base_url}/api/profile_picture/${localStorage.getItem("username")}?timestamp=${timestamp}`;
                            headerProfilePicture.src = imageUrl;
                            profilePicture.src = imageUrl;
                            // console.log('profile picture updated');
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    loadingPicture.value = false;
                }
            };
            fileInput.click();
        };

        const updateInfo = async (key, value) => {
            value = document.getElementById(value).value;

            if (value.trim() === '') {
                return;
            }

            const data = {
                [key]: value.trim(),
            };


            try {
                const response = await http.post('/user_info_update', data);

                if (response.status === 200) {
                    basicInfo.value[key] = value;
                    const currentData = JSON.parse(localStorage.getItem('user_roles'));
                    currentData.basicinfo[key] = value;
                    localStorage.setItem('user_roles', JSON.stringify(currentData));
                }

            } catch (error) {
                console.log(error);
            }
        };

        const openInviteModal = () => {
            inviteModalElement = new Modal(document.getElementById('inviteUserModal'));
            ctx.emit('toggleConfigModal')
            inviteModalElement.show();
        }

        const selectTab = (tabIndex) => {
            document.getElementById(tabList[tabIndex].tab).click();
        };

        onMounted(() => {
            const inviteModal = document.getElementById('inviteUserModal');
            const configModal = document.getElementById('configModal');

            configModal.addEventListener('shown.bs.modal', () => {
                selectTab(props.tabToShow);
            })

            inviteModal.addEventListener('hidden.bs.modal', () => {
                ctx.emit('toggleConfigModal', 2)
            })

        });

        return {
            basicInfo,
            updateInfo,
            updateProfilePicture,
            loadingPicture,
            userAdmin,
            openInviteModal,
            selectTab
        }

    },

    watch: {
        tabToShow(newValue) {
            this.$nextTick(() => {
                this.selectTab(newValue);
            })
        }
    },

    methods: {
        getProfilePicture() {
            const base_url = window.location.origin;
            return base_url + '/api/profile_picture/' + localStorage.getItem("username");
        }
    }
}

</script>

<style scoped>
.form-check-input {
    width: 3em !important;
    height: 1.3rem !important;
    margin-top: 0rem;
    margin-right: 0.5rem;
}

.form-floating> :disabled~label::after,
.form-floating>.form-control:disabled~label::after {
    background-color: transparent !important;
}

.spinner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 95px;
    height: 95px;
}

.hover-div {
    position: absolute;
    width: 95px;
    height: 95px;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    cursor: pointer;
    border-radius: 100px;
}

.hover-div:hover {
    opacity: 1;
}

.hover-div:hover h5 {
    display: block !important;
}
</style>