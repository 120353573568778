<template>
    <!-- <div class="d-flex align-items-center no-wrap logo-img" style="height: 60px;">
      <img src="../../assets/images/inteleprint_logo_square.png" width="50" alt="" class="mx-1"/>
      <span class='text-primary'>Intele</span><span>print</span>
    </div> -->

      <div class="d-flex justify-content-center align-items-center">
        <img src="../../assets/images/logos/kahun_logo_dark.png" alt="petcon" class="img-fluid" v-if="theme == 'dark'" style="height: 45px; width: auto;" />
        <img src="../../assets/images/logos/kahun_logo_light.png" alt="petcon" class="img-fluid" v-else style="height: 45px; width: auto;" />
    </div>
</template>

<script>

export default {
  name: "HeaderLogo",
  props: {
    theme: String
  }
}

</script>

<style scoped>

.logo-img {
    -webkit-user-select: none !important; /* Safari */
    -moz-user-select: none !important; /* Firefox */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Padrão */
    user-select: none;
    pointer-events: none;
    margin-right: 10px;
}

.logo-img span {
    font-family: 'ubuntu', sans-serif;
    font-size: 1.8rem;
    color: var(--bs-emphasis-color);
  }

</style>