<template>
    <div class="col" @click="goToCourse()">
        <div class="card p-0" :class="{ 'shadow': isHover }" @mouseover="isHover = true" @mouseleave="isHover = false">
            <div class="d-relative">
                <div class="position-absolute top-0 end-0 p-2">
                    <div v-if="editable" class="rounded-circle p-2 text-center edit-course" style="width: 40px; height: 40px"
                        @click.prevent.stop="this.$emit('editCourse', courseData.id)">
                        <i class="bi bi-pen text-white fs-4"></i>
                    </div>
                </div>
                <img :src="`${locationURL}/api/course_cover/${courseData.id}`" class="card-img-top"
                    :alt="courseData.slug">
            </div>
            <div class="card-body p-4">
                <p class="card-title fw-bold mb-1"
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 100%">{{
        courseData.name }}</p>
                <p class="card-text text-muted fs-mini">{{ courseData.coordinator.name + ' ' +
        courseData.coordinator.surname }}</p>
                <div style="height: 28px;">
                    <div v-if="courseData.draft == false && courseData.visible == true">
                        <div class="progress mt-3 mb-1" style="height: 5px;">
                            <div class="progress-bar bg-success" role="progressbar"
                                :style="`width: ${courseData.bookmark.progress}%; height: 5px;`"
                                :aria-valuenow="courseData.bookmark.progress" aria-valuemin="0" aria-valuemax="100">
                            </div>
                        </div>
                        <p class="card-text text-muted fs-mini">{{ courseData.bookmark.progress }}% concluído</p>
                    </div>
                    <div v-else class="hstack gap-1">
                        <span v-if="courseData.draft == true" class="badge fs-mini" style="background-color: rgba(var(--bs-danger-rgb), 0.4);">Rascunho</span>
                        <span v-if="courseData.visible == false" class="badge fs-mini" style="background-color: rgba(var(--bs-info-rgb), 0.4);">Invisível</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/services/http"
import { onMounted, ref } from 'vue';

export default {

    name: 'CourseCard',
    props: ['courseData', 'editable'],
    emits: ['notifyError', 'editCourse'],
    data() {
        return {
            isHover: false
        };
    },
    setup() {
        const locationURL = ref('');

        onMounted(() => {
            locationURL.value = window.location.origin;
        });

        return {
            locationURL
        }
    },

    methods: {
        async goToCourse() {
            try {
                const { data } = await http.get('/get_bookmark/' + this.courseData.id);

                if (data.lesson) {
                    window.location.href = `/learning/${this.courseData.slug}/lecture/${data.lesson}`;
                }

            } catch (error) {
                this.$emit('notifyError', { message: "<h5 class='fw-bold text-white mb-2'>Falha ao conectar!</h5><p class='text-white mb-0'>Verifique a sua conexão com a rede</p><p class='text-white mb-0'>Err.:" + error.response.status + "</p>", id: 1 });
            }
        }
    },
}
</script>

<style scoped>
a:hover>.card {
    transform: scale(1.008);
    transition: all 0.2s ease-in-out;
}

.card {
    cursor: pointer;
}

.card-title {
    font-size: 0.9rem;
    color: var(--bs-emphasis-color) !important;
}

.card-img-top {
    height: 200px;
    object-fit: cover;
}

.fs-mini {
    font-size: 0.8rem;
}

.edit-course:hover {
    background-color: var(--bs-info);
}
</style>