<template>
    <div>
      <div class="wrapper container-nav">
        <nav class="sidebar-nav scroll-sidebar navbar-vertical" data-simplebar="">
          <SideBarItems :id="id" @logout="this.$emit('logout', $event)" @changeUnit="this.$emit('changeUnit', $event)"  @config="this.$emit('config', $event)"/>
        </nav>
      </div>
    </div>
</template>

<script>
import SideBarItems from './SideBarItems.vue';

export default {
    name: 'SideBar',
    props: {
    id: {
        type: String,
        required: true
      }
    },
    components: {
        SideBarItems,
      },
    emits: ['logout', 'changeUnit', 'config'],
}
</script>

<style scoped>

/* Estilize a barra de rolagem */
::-webkit-scrollbar {
  width: 5px; /* Largura da barra de rolagem */
}

aside div, .left-sidebar {
    background-color: var(--bs-body-bg)!important;
  }

.left-sidebar {
  border-right: 1px solid var(--bs-secondary-border-subtle);
}

</style>