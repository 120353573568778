<template>
    <div class="container p-5 d-flex align-items-center justify-content-center" id="wrapper">
        <div class="container d-flex align-items-center justify-content-center flex-column"
            style="max-width: 650px">
            <HeaderLogo />
            <h1 class="text-center mt-4">Em breve!</h1>
            <h5 class="text-center my-4">A Kahun está preparando novidades para você! Fique atento aos canais oficiais, que iremos te informar das atualizaçõe em nosso sistema.
            </h5>
            <img src="../../assets/images/images/female chef-rafiki.svg">
        </div>
    </div>
</template>

<script>


export default {
  name: 'HelloWorld',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
    max-height: 50vh;
    width: auto;
}

#wrapper {
    min-height: 100%;
    min-width: 100%;
}

</style>
